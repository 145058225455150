import { Component } from '@angular/core';
import {
  RouterModule,
  Router,
  RouterOutlet,
  RouterLink,
} from '@angular/router';
import { CtaButtonComponent } from '../components/cta-button/cta-button.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-nav',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    RouterOutlet,
    RouterLink,
    CtaButtonComponent,
  ],
  templateUrl: './nav.component.html',
  styleUrl: './nav.component.css',
})
export class NavComponent {
  isDrawerOpen: boolean = false;

  constructor(private router: Router) {}

  handleLearnMoreButtonClick(event: Event): void {
    this.router.navigate(['/about-us']);
  }

  toggleDrawer(): void {
    this.isDrawerOpen = !this.isDrawerOpen;
  }
}
