<footer>
    <div class="flex flex-col">
        <!-- Top Divider-->
        <div class="bg-slate-200 h-[1px]"></div>
        <!-- Parent Container -->
        <!--Layout-->
        <div class="flex flex-col p-4">
          
            <!-- Contact Infos -->
            <div class="grid sm:grid-cols-3 gap-6 md:grid-cols-3 place-self-center">
                <div class="inline justify-self-center sm:justify-self-center">
                    <img class="inline mr-2" src="../../assets/icons/envelope.svg" alt="" width="20" height="20">
                    <a href="mailto:service@acte2s.com" class="hover:text-appRed">service&#64;ACTE2S.com</a>
                </div>
                <div class="inline justify-self-center sm:justify-self-center">
                    <img class="inline mr-2" src="../../assets/icons/fax.svg" alt="" width="20" height="20">
                    <a href="tel:+17125603014" class="hover:text-appRed" target="_self"
                        aria-label="Go to tel:17125603014">(712) 560-3014</a>
                </div>
                <div class="inline justify-self-center sm:justify-self-center">
                    <img class="inline mr-2" src="../../assets/icons/phone.svg" alt="" width="20" height="20">
                    <a href="tel:+18772283274" class="hover:text-appRed" target="_self"
                        aria-label="Go to tel:+18772283274">(877) ACTE2S-4</a>
                </div>
                <div class="inline justify-self-center sm:justify-self-center">
                    <img class="inline mr-2" src="../../assets/icons/phone.svg" alt="" width="20" height="20">
                    <a href="tel:+7122239989" class="hover:text-appRed" target="_self"
                        aria-label="Go to tel:+7122239989">(712) 223-9989</a>
                </div>
                <div class="grid grid-flow-col gap-2 justify-self-center sm:justify-self-center">
                    <div class="justify-self-end">
                        <img src="../../assets/icons/location.svg" alt="" width="20" height="20">
                    </div>
                    <div class="justify-self-start">
                        <a href="https://www.google.com/maps/place/4121+Gordon+Dr,+Sioux+City,+IA+51106/@42.4817602,-96.353169,17z/data=!3m1!4b1!4m6!3m5!1s0x878dfc45428594ab:0x6a7e75d13d679313!8m2!3d42.4817602!4d-96.353169!16s%2Fg%2F11h05jb93r?entry=ttu"
                            class="hover:text-appRed">4121 Gordon Dr Sioux City, IA 51106</a>
                    </div>
                </div>
                <div class="justify-self-center sm:justify-self-center">
                    <a href="https://www.facebook.com/profile.php?id=100089087146543">
                        <img class="inline mr-2" src="../../assets/icons/facebook.svg" alt="" width="30" height="30">
                    </a>
                    <a href="https://www.linkedin.com/company/acte2s">
                        <img class="inline mr-2" src="../../assets/icons/linkedin.svg" alt="" width="30" height="30">
                    </a>
                </div>
            </div>
        </div>

        <!--Navigation Elements Container-->


        <!--Grid Wrapper-->
        <div class="flex justify-center p-4">
            <div class="justify-items-center mx-2 max-w-4xl grid grid-cols-3 gap-2 sm:grid-cols-5">

                <!--Columns-->
                <div class="flex-shrink auto-center">
                    <a routerLink="/environmental"
                        class="p-1 flex-shrink inline-block text-xs text-appBlue hover:text-appRed">Environmental</a>
                </div>
                <div class="flex-shrink">
                    <a routerLink="/engineering"
                        class="flex-shrink p-1 inline-block text-xs text-appBlue hover:text-appRed">Engineering</a>
                </div>
                <div class="flex-grow col-span-1">
                    <a routerLink="/health-safety"
                        class="flex-grow p-1 inline-block text-xs text-appBlue hover:text-appRed">Safety-Health</a>
                </div>
                <div class="flex-shrink">
                    <a routerLink="/capabilities"
                        class="flex-shrink p-1 inline-block text-xs text-appBlue hover:text-appRed">Capabilities</a>
                </div>
                <div class="flex-shrink">
                    <a routerLink="/careers"
                        class="flex-shrink p-1 inline-block text-xs text-appBlue hover:text-appRed">Careers</a>
                </div>
                <div class="flex-shrink">
                    <a routerLink="/past-performance"
                        class="flex-shrink p-1 inline-block text-xs text-appBlue hover:text-appRed">Past Performance</a>
                </div>
                <div class="flex-shrink">
                    <a routerLink="/project-media"
                        class="flex-shrink p-1 inline-block text-xs text-appBlue hover:text-appRed">Project Media</a>
                </div>
                <div class="flex-shrink">
                    <a routerLink="/about-us"
                        class="flex-shrink p-1 inline-block text-xs text-appBlue hover:text-appRed">About Us</a>
                </div>
                <div class="flex-shrink">
                    <a routerLink="/contact-us"
                        class="flex-shrink p-1 inline-block text-xs text-appBlue hover:text-appRed">Contact Us</a>
                </div>
                <div class="invisible sm:flex-shrink sm:visible">
                    <a href="https://theblackledgegroup.com"
                        class="flex-grow p-1 inline-block text-xs text-appBlue hover:text-appRed">Joint Ventures</a>
                </div>
                <div
                    class="justify-items-center flex-shrink grid grid-cols-subgrid col-start-2 sm:hidden">
                    <a href="https://theblackledgegroup.com"
                        class="flex-grow p-1 inline-block text-xs text-appBlue hover:text-appRed">Joint Ventures</a>
                </div>
            </div>
        </div>

        <!--Brochure button-->
        <div class="flex mx-auto p-4">
            <!-- Learn More Button -->
            <a href="../../assets/pdfs/Tri-fold-Aug-2022-aa90d8d.pdf" target="_blank" class="bg-appRed hover:bg-appBlue text-center text-white py-6 w-48">Download Our Brochure</a>
        </div>

        <!--Copyright-->
        <div class="bg-appBlue p-4 text-left text-surface text-white">
            © 2024 ACTE2S. All Rights Reserved
        </div>
    </div>
</footer>