import { Component, Input } from '@angular/core';
import { ThemeService } from '../../theme/theme-service';

@Component({
  selector: 'app-checkmark-item',
  standalone: true,
  imports: [],
  templateUrl: './checkmark-item.component.html',
  styleUrl: './checkmark-item.component.css',
})
export class CheckmarkItemComponent {
  @Input() title: string = '';
  @Input() color: string = '';

  constructor(themeService: ThemeService) {
    this.color = themeService.getColor(this.color);
  }
}
