<div class="card">
    <app-accordion>
        <app-accordion-item title="Environmental">
            <div class="flex flex-col items-center justify-center p-4">
                <img class="w-full h-auto mb-4 rounded shadow-lg" src="../../assets/trucks.jpg" alt="">
                <p class="p-4">Our environmental department employs a variety of scientists, engineers, and technicians
                    that provide cost-effective, reliable solutions. Their knowledge base includes federal programs such
                    as:
                </p>
                <ul class="space-y-0 w-full p-4 bg-slate-100 rounded">
                    <li class="flex h-10">
                        <div>
                            <div class="w-6 h-6">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                                    fill="#113c5c"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                                    <path
                                        d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                                </svg>
                            </div>
                        </div>
                        <span class="ml-4">Air</span>
                    </li>
                    <li class="flex h-10">
                        <div>
                            <div class="w-6 h-6">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                                    fill="#113c5c"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                                    <path
                                        d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                                </svg>
                            </div>
                        </div>
                        <span class="ml-4">Cross-cutting Issues</span>
                    </li>
                    <li class="flex h-10">
                        <div>
                            <div class="w-6 h-6">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                                    fill="#113c5c"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                                    <path
                                        d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                                </svg>
                            </div>
                        </div>
                        <span class="ml-4"> Emergencies</span>
                    </li>
                    <li class="flex h-10">
                        <div>
                            <div class="w-6 h-6">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                                    fill="#113c5c"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                                    <path
                                        d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                                </svg>
                            </div>
                        </div>
                        <span class="ml-4">Land & Cleanup (Remediation)</span>
                    </li>
                    <li class="flex h-10">
                        <div>
                            <div class="w-6 h-6">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                                    fill="#113c5c"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                                    <path
                                        d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                                </svg>
                            </div>
                        </div>
                        <span class="ml-4">Pesticides</span>
                    </li>
                    <li class="flex h-10">
                        <div>
                            <div class="w-6 h-6">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                                    fill="#113c5c"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                                    <path
                                        d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                                </svg>
                            </div>
                        </div>
                        <span class="ml-4"> Toxic Substances</span>
                    </li>
                    <li class="flex h-10">
                        <div>
                            <div class="w-6 h-6">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                                    fill="#113c5c"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                                    <path
                                        d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                                </svg>
                            </div>
                        </div>
                        <span class="ml-4"> Waste</span>
                    </li>
                    <li class="flex h-10">
                        <div>
                            <div class="w-6 h-6">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                                    fill="#113c5c"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                                    <path
                                        d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                                </svg>
                            </div>
                        </div>
                        <span class="ml-4">Water</span>
                    </li>
                </ul>
            </div>
        </app-accordion-item>
        <app-accordion-item title="Engineering">At ACTE2S we are proud of providing innovative solutions to real world
            problems while maintain environmental integrity, safety, and regulatory compliance. ACTE2S partners with
            only experienced, professional, partners to provide the customer with a full line of engineering design,
            consulting, project management, and environmental engineering consulting services. Lorem ipsum dolor sit
            amet consectetur adipisicing elit. Laudantium tenetur enim eligendi voluptatum blanditiis quos omnis aperiam
            illo, distinctio, inventore sed ullam ipsam sapiente maxime? Repudiandae molestias repellat quis maiores?
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati illo et nulla excepturi, debitis error id
            ex in, nobis tempore asperiores minima odio dolore optio, ab provident quidem doloremque molestiae? Lorem
            ipsum dolor sit amet consectetur adipisicing elit. Nisi, corporis minus. Ea vel necessitatibus doloremque
            expedita! Blanditiis deserunt maxime nulla ipsum ut saepe architecto, doloremque in! Rerum assumenda optio
            debitis.At ACTE2S we are proud of providing innovative solutions to real world
            problems while maintain environmental integrity, safety, and regulatory compliance. ACTE2S partners with
            only experienced, professional, partners to provide the customer with a full line of engineering design,
            consulting, project management, and environmental engineering consulting services. Lorem ipsum dolor sit
            amet consectetur adipisicing elit. Laudantium tenetur enim eligendi voluptatum blanditiis quos omnis aperiam
            illo, distinctio, inventore sed ullam ipsam sapiente maxime? Repudiandae molestias repellat quis maiores?
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati illo et nulla excepturi, debitis error id
            ex in, nobis tempore asperiores minima odio dolore optio, ab provident quidem doloremque molestiae? Lorem
            ipsum dolor sit amet consectetur adipisicing elit. Nisi, corporis minus. Ea vel necessitatibus doloremque
            expedita! Blanditiis deserunt maxime nulla ipsum ut saepe architecto, doloremque in! Rerum assumenda optio
            debitis.At ACTE2S we are proud of providing innovative solutions to real world
            problems while maintain environmental integrity, safety, and regulatory compliance. ACTE2S partners with
            only experienced, professional, partners to provide the customer with a full line of engineering design,
            consulting, project management, and environmental engineering consulting services. Lorem ipsum dolor sit
            amet consectetur adipisicing elit. Laudantium tenetur enim eligendi voluptatum blanditiis quos omnis aperiam
            illo, distinctio, inventore sed ullam ipsam sapiente maxime? Repudiandae molestias repellat quis maiores?
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati illo et nulla excepturi, debitis error id
            ex in, nobis tempore asperiores minima odio dolore optio, ab provident quidem doloremque molestiae? Lorem
            ipsum dolor sit amet consectetur adipisicing elit. Nisi, corporis minus. Ea vel necessitatibus doloremque
            expedita! Blanditiis deserunt maxime nulla ipsum ut saepe architecto, doloremque in! Rerum assumenda optio
            debitis.At ACTE2S we are proud of providing innovative solutions to real world
            problems while maintain environmental integrity, safety, and regulatory compliance. ACTE2S partners with
            only experienced, professional, partners to provide the customer with a full line of engineering design,
            consulting, project management, and environmental engineering consulting services. Lorem ipsum dolor sit
            amet consectetur adipisicing elit. Laudantium tenetur enim eligendi voluptatum blanditiis quos omnis aperiam
            illo, distinctio, inventore sed ullam ipsam sapiente maxime? Repudiandae molestias repellat quis maiores?
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati illo et nulla excepturi, debitis error id
            ex in, nobis tempore asperiores minima odio dolore optio, ab provident quidem doloremque molestiae? Lorem
            ipsum dolor sit amet consectetur adipisicing elit. Nisi, corporis minus. Ea vel necessitatibus doloremque
            expedita! Blanditiis deserunt maxime nulla ipsum ut saepe architecto, doloremque in! Rerum assumenda optio
            debitis.At ACTE2S we are proud of providing innovative solutions to real world
            problems while maintain environmental integrity, safety, and regulatory compliance. ACTE2S partners with
            only experienced, professional, partners to provide the customer with a full line of engineering design,
            consulting, project management, and environmental engineering consulting services. Lorem ipsum dolor sit
            amet consectetur adipisicing elit. Laudantium tenetur enim eligendi voluptatum blanditiis quos omnis aperiam
            illo, distinctio, inventore sed ullam ipsam sapiente maxime? Repudiandae molestias repellat quis maiores?
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati illo et nulla excepturi, debitis error id
            ex in, nobis tempore asperiores minima odio dolore optio, ab provident quidem doloremque molestiae? Lorem
            ipsum dolor sit amet consectetur adipisicing elit. Nisi, corporis minus. Ea vel necessitatibus doloremque
            expedita! Blanditiis deserunt maxime nulla ipsum ut saepe architecto, doloremque in! Rerum assumenda optio
            debitis.At ACTE2S we are proud of providing innovative solutions to real world
            problems while maintain environmental integrity, safety, and regulatory compliance. ACTE2S partners with
            only experienced, professional, partners to provide the customer with a full line of engineering design,
            consulting, project management, and environmental engineering consulting services. Lorem ipsum dolor sit
            amet consectetur adipisicing elit. Laudantium tenetur enim eligendi voluptatum blanditiis quos omnis aperiam
            illo, distinctio, inventore sed ullam ipsam sapiente maxime? Repudiandae molestias repellat quis maiores?
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati illo et nulla excepturi, debitis error id
            ex in, nobis tempore asperiores minima odio dolore optio, ab provident quidem doloremque molestiae? Lorem
            ipsum dolor sit amet consectetur adipisicing elit. Nisi, corporis minus. Ea vel necessitatibus doloremque
            expedita! Blanditiis deserunt maxime nulla ipsum ut saepe architecto, doloremque in! Rerum assumenda optio
            debitis.</app-accordion-item>
        <app-accordion-item title="Safety (Occupational Safety and Health)">ACTE2S is a multifaceted regulatory
            compliance service provider assisting companies with local, state, and federal regulatory compliance. We
            have thousands of years combined experience as former regulators and compliance managers. Our staff are
            degrees, licensed and highly experienced Occupational Safety and Health professionals with real world
            experience and coaches. Our passion and professionalism shows through our long term client
            relationships.</app-accordion-item>
    </app-accordion>

</div>