<!-- Parent -->
<div class="flex flex-col pb-4">
    <!-- Hero Background -->
    <div class="min-h-screen bg-hero-normal bg-cover bg-center relative">
        <!-- Hero Content -->
        <div
            class="absolute inset-0 bg-gradient-to-t from-white/80 to-white/20 flex flex-col items-center lg:space-y-28">
            <!-- Headlines -->
            <div class="mt-2 text-appBlue font-[Oswald] font-bold text-center space-y-5 max-w-5xl sm:mt-4 sm:p-4">
                <h1 class="text-3xl sm:text-6xl">Specializing in Environmental,<br>Engineering and Safety Services</h1>
                <h2 class="text-3xl ">We will serve you with the same loyalty, honor, integrity, respect, and
                    selflessness
                    as
                    we
                    served our country.
                </h2>
            </div>
            <!-- Scheule Consultation Button -->
            <app-cta-button class="m-8" title="Schedule a Consultation"
                (buttonClick)="handleContactUsButtonClick($event)"></app-cta-button>
            <!-- Logos -->
            <div class="w-full flex flex-col items-center bg-white sm:flex-row max-w-5xl">
                <div class="w-full px-4">
                    <img class="" src="../../../assets/logo/ACTE2S-logo.svg" alt="">
                </div>
                <div class="max-w-[75px] sm:max-w-[150px]">
                    <img class="" src="../../../assets/icons/exchange-arrows.png" alt="">
                </div>
                <div class="w-full p-3">
                    <img class="h-full w-full" src="../../../assets/logo/ACTSAFE-logo.jpg" alt="">
                </div>
            </div>
        </div>
    </div>
    <!-- Accordions -->
    <div class="bg-white m-2 flex flex-col justify-center md:flex-row md:space-x-4 md:m-5 z-10">
        <!-- Group 1 -->
        <div class="md:w-1/2 md:max-w-lg">
            <app-accordion>
                <!-- Environmental -->
                <app-accordion-item title="Environmental">
                    <div class="flex flex-col items-center justify-center">
                        <img class="w-full h-auto mb-4 rounded shadow-lg" src="../../../assets/images/normal/trucks.jpg"
                            alt="">
                        <p class="p-4">Our environmental department employs a variety of scientists, engineers, and
                            technicians
                            that provide cost-effective, reliable solutions. Their knowledge base includes federal
                            programs such
                            as:
                        </p>
                        <ul class="space-y-0 w-full p-4 bg-slate-100 rounded">
                            <app-checkmark-item title="Air"></app-checkmark-item>
                            <app-checkmark-item title="Cross-cutting Issues"></app-checkmark-item>
                            <app-checkmark-item title="Emergencies"></app-checkmark-item>
                            <app-checkmark-item title="Land & Cleanup (Remediation)"></app-checkmark-item>
                            <app-checkmark-item title="Pesticides"></app-checkmark-item>
                            <app-checkmark-item title="Toxic Substances"></app-checkmark-item>
                            <app-checkmark-item title="Waste"></app-checkmark-item>
                            <app-checkmark-item title="Water"></app-checkmark-item>
                        </ul>
                    </div>
                </app-accordion-item>
                <!-- Engineering -->
                <app-accordion-item title="Engineering">
                    <div class="flex flex-col items-center justify-center">
                        <img class="w-full h-auto mb-4 rounded shadow-lg"
                            src="../../../assets/images/normal/electrical-box.jpg" alt="">
                        <p class="p-4">At ACTE2S we are proud of providing innovative solutions to real world problems
                            while maintaining environmental integrity, safety, and regulatory compliance. ACTE2S
                            partners
                            with only experienced, professional, partners to provide the customer with a full line of
                            engineering design, consulting, project management, and environmental engineering consulting
                            services.
                        </p>
                        <ul class="space-y-0 w-full p-4 bg-slate-100 rounded">
                            <app-checkmark-item title="Building Green"></app-checkmark-item>
                            <app-checkmark-item title="Engineering Support"></app-checkmark-item>
                            <app-checkmark-item title="Environmental Engineering"></app-checkmark-item>
                            <app-checkmark-item title="Architectural Design"></app-checkmark-item>
                        </ul>
                    </div>
                </app-accordion-item>
                <!-- Safety -->
                <app-accordion-item title="Occupational Safety and Health">
                    <div class="flex flex-col items-center justify-center">
                        <img class="w-full h-auto mb-4 rounded shadow-lg"
                            src="../../../assets/images/normal/fall-protection.jpg" alt="">
                        <p class="p-4">ACTE2S is a multifaceted regulatory compliance service provider assisting
                            companies with local, state, and federal regulatory compliance. We have thousands of years
                            combined experience as former regulators and compliance managers. Our staff are degrees,
                            licensed and highly experienced Occupational Safety and Health professionals with real world
                            experience and coaches. Our passion and professionalism shows through our long term client
                            relationships.
                        </p>
                        <ul class="space-y-0 w-full p-4 bg-slate-100 rounded">
                            <app-checkmark-item title="Emergency Response - HazWOPER"></app-checkmark-item>
                            <app-checkmark-item
                                title="Expert Third Party Audits / Incident - Accident Investigation"></app-checkmark-item>
                            <app-checkmark-item title="General & Construction Industry Safety"></app-checkmark-item>
                            <app-checkmark-item title="Industrial Hygiene"></app-checkmark-item>
                            <app-checkmark-item title="Job Safety Analysis"></app-checkmark-item>
                            <app-checkmark-item title="Life Safety & Compliance Inspections"></app-checkmark-item>
                            <app-checkmark-item
                                title="	Training - Over 500 courses (Licensed OSHA Outreach Instructors)"></app-checkmark-item>
                        </ul>
                    </div>
                </app-accordion-item>
            </app-accordion>
        </div>
        <!-- Group 2 -->
        <div class="md:w-1/2 md:max-w-lg">
            <app-accordion>
                <!-- Services We Offer -->
                <app-accordion-item title="Services We Offer">
                    <div class="flex flex-col items-center justify-center">
                        <img class="w-full h-auto mb-4 rounded shadow-lg" src="../../../assets/images/normal/derail.jpg"
                            alt="">
                        <ul class="space-y-4 w-full p-4 bg-slate-100 rounded">
                            <app-checkmark-item title="Audit / Inspection"></app-checkmark-item>
                            <app-checkmark-item title="Environmental Services & Due Diligence"></app-checkmark-item>
                            <app-checkmark-item title="Environmental Remediation"></app-checkmark-item>
                            <app-checkmark-item title="Engineering Services"></app-checkmark-item>
                            <app-checkmark-item
                                title="Expert Representative / Negotiator / Witness"></app-checkmark-item>
                            <app-checkmark-item
                                title="Management Solutions (Part-Time or Full-Time)"></app-checkmark-item>
                            <app-checkmark-item title="Policy / Procedure / Program Management"></app-checkmark-item>
                            <app-checkmark-item title="Permitting / Report Submission"></app-checkmark-item>
                            <app-checkmark-item title="Training"></app-checkmark-item>
                            <app-checkmark-item title="Waterfront Engineering"></app-checkmark-item>
                        </ul>
                    </div>
                </app-accordion-item>
                <!-- Trusted Solution Provider -->
                <app-accordion-item title="Trusted Solution Provider">
                    <div class="flex flex-col items-center justify-center">
                        <img class="w-full h-auto mb-4 rounded shadow-lg"
                            src="../../../assets/images/normal/abandon-in-place.jpg" alt="">
                        <p class="p-4">ACTE2S began in 2013 as a cooperative of former EPA, OSHA, and DOT regulators
                            along with seasoned compliance managers. We provide a full range of compliance services
                            focused on addressing the needs of clients in the private and public sectors.
                        </p>
                    </div>
                </app-accordion-item>
            </app-accordion>
        </div>
    </div>
    <!-- Differentiators -->
    <div class="bg-differentiators min-h-[500px] bg-cover bg-center relative">
        <div
            class="absolute inset-0 bg-gradient-to-t from-white/80 to-white/60 flex flex-col items-center justify-center p-4 space-y-10">
            <div class="max-w-3xl text-center space-y-4">
                <h2 class="text-2xl font-[Oswald] text-appBlue">DIFFERENTIATORS:</h2>
                <p class="bg-slate-200/70 p-2 rounded-lg font-semibold">ACTE2S is unique in so much as we are retired
                    military and
                    active
                    reservist /
                    guard.
                    Our team has touched
                    almost every business and industry in the private and public sectors allowing us to be uniquely
                    qualified to understand the wide range of issues our clients face. We are motivated to long term
                    relationships and have a “Can Do” attitude with the perseverance to do so.</p>
            </div>
            <app-cta-button title="How may we Serve You?"
                (buttonClick)="handleContactUsButtonClick($event)"></app-cta-button>
        </div>
    </div>
    <!-- Certs -->
    <div
        class="bg-white grid grid-cols-2 gap-2 place-items-center p-2 sm:grid-cols-4 lg:grid-cols-7 max-w-[1145px] mx-auto">
        <img class="h-32" src="../../../assets/certs/WVOSB-ribbon.jpg" alt="">
        <img class="h-32" src="../../../assets/certs/sdvosb-wreath.svg" alt="">
        <img class="col-span-2 max-h-32" src="../../../assets/certs/GSA-Schedule-Numbered.png" alt="">
        <img class="h-32" src="../../../assets/certs/SBA-8(a).png" alt="">
        <img class="h-32" src="../../../assets/certs/SBA-EDWOSB.png" alt="">
        <img class="h-32" src="../../../assets/certs/SBA-WOSB.png" alt="">
    </div>
    <!-- Checkmarks -->
    <div class="">
        <!-- Flex -->
        <div class=" flex flex-col p-4 space-y-4 md:flex-row md:space-y-0 justify-evenly lg:justify-center">
            <!-- Designations / Registration-->
            <div class="flex flex-col space-y-4 lg:flex-row lg:space-y-0">
                <!-- Designations -->
                <div class="flex flex-col">
                    <h2 class="text-[24px] font-[Oswald] text-appBlue">Designations</h2>
                    <ng-container *ngFor="let designation of designations">
                        <app-checkmark-item title="{{ designation }}" [color]="getColor('appRed')"></app-checkmark-item>
                    </ng-container>
                </div>
                <!-- Registrations -->
                <div class="flex flex-col lg:m-4">
                    <h2 class="text-[24px] font-[Oswald] text-appBlue">Registrations</h2>
                    <ng-container *ngFor="let registration of registrations">
                        <app-checkmark-item title="{{ registration }}"
                            [color]="getColor('appRed')"></app-checkmark-item>
                    </ng-container>
                </div>
            </div>
            <!-- NAICS Grid -->
            <div class="grid grid-cols-2 gap-2 lg:m-4">
                <h2 class="text-[24px] font-[Oswald] text-appBlue col-span-2">NAICS Codes</h2>
                <ng-container *ngFor="let code of naicsCodes">
                    <app-checkmark-item title="{{ code }}" [color]="getColor('appRed')"></app-checkmark-item>
                </ng-container>
            </div>
        </div>
    </div>
    <!-- GSA Schedule -->
    <div class="mx-auto text-lg font-semibold font-sans">
        <p>GSA Schedule - Exp. 10-18-2026 Contract No. 47QRAA22D000Y</p>
    </div>
</div>