<div class="relative self-start">
  <div *ngFor="let image of images; let i = index"
    class="opacity-0 absolute transition-opacity duration-1000 ease-in-out"
    [ngClass]="{ 'opacity-100': i === activeIndex }">
    <img [src]="image" alt="Slide" class="w-full h-full object-cover" />
  </div>

  <!-- Buttons -->
  <div class="flex justify-between absolute w-full">
    <button (click)="prevImage()" class="text-appBlue text-3xl focus:outline-none hover:text-white">
      &#10094;
    </button>
    <button (click)="nextImage()" class="text-appBlue text-3xl focus:outline-none hover:text-white">
      &#10095;
    </button>
  </div>
</div>