<!-- Parent -->
<div class="flex flex-col">
    <!-- Header -->
    <div class="mt-2 font-[Oswald] font-bold text-center max-2-5xl sm:mt-4 sm:p-4">
        <h1 class="text-4xl text-appBlue">Experts in </h1>
        <h2 class="text-4xl text-appRed">Environmental and Remediation Services</h2>
    </div>
    <!-- Accordians -->
    <div class="bg-white m-2 flex flex-col justify-center md:flex-row md:space-x-4 md:m-5 z-10">
        <!-- Group 1 -->
        <div class="md:w-1/2 md:max-w-lg">
            <app-accordion>
                <!-- Air Quaility -->
                <app-accordion-item title="Air Quality">
                    <div class="flex flex-col items-center justify-center">
                        <p class="p-4">
                            ACTE2S provides a full range of air quality services for all types of industrial,
                            commercial, and governmental facilities.
                            We assist our clients from initial facility planning and site selection to permitting new
                            construction and modifications.
                            Our expertise in state, local, and federal regulations and permitting requirements enables
                            us to efficiently assist our clients with addressing new rules,
                            as well as managing change at their facilities. Our air quality consultants have the
                            real-world experience working in industry, as well as within state air permitting and
                            compliance agencies.
                        </p>
                        <img class="" src="../../../assets/images/normal/Air-Quaility_H.png" alt="">
                        <p class="p-4">
                            <strong>Asbestos and Lead Services:</strong>
                        </p>
                        <ul class="space-y-0 w-full p-4 bg-slate-100 rounded">
                            <app-checkmark-item
                                title="Municipal HUD LBP Program Management Services"></app-checkmark-item>
                            <app-checkmark-item title="XRF Lead-Based Paint Sampling"></app-checkmark-item>
                            <app-checkmark-item title="Lead-Based Paint Inventories"></app-checkmark-item>
                            <app-checkmark-item title="Lead Risk Assessment"></app-checkmark-item>
                            <app-checkmark-item
                                title="Lead-Based Paint Remediation Specifications"></app-checkmark-item>
                            <app-checkmark-item title="Lead Clearance Inspections"></app-checkmark-item>
                            <app-checkmark-item title="Asbestos Sampling"></app-checkmark-item>
                            <app-checkmark-item title="Asbestos Inventories"></app-checkmark-item>
                            <app-checkmark-item title="Asbestos Project Designs"></app-checkmark-item>
                            <app-checkmark-item
                                title="Demolition Specs for ACM-Containing Structures"></app-checkmark-item>
                            <app-checkmark-item title="Air Monitoring"></app-checkmark-item>
                            <app-checkmark-item title="Personal Exposure Monitoring"></app-checkmark-item>
                        </ul>
                    </div>
                </app-accordion-item>
                <!-- Emergency Planning and Community Right-to-Know Act (EPCRA) -->
                <app-accordion-item title="Emergency Planning and Community Right-to-Know Act (EPCRA)">
                    <div class="flex flex-col items-center justify-center">
                        <p class="p-4">
                            According to the EPCRA chemical reporting requirements, facilities must report the storage,
                            use, and release of certain hazardous chemicals.
                            ACTE2S assists our clients with reporting, managing submissions
                            and training requirements of the following:
                        </p>
                        <img class="" src="../../../assets/images/normal/a.png" alt="">
                        <ul class="space-y-0 w-full p-4 bg-slate-100 rounded">
                            <app-checkmark-item title="Emergency Planning Notification"></app-checkmark-item>
                            <app-checkmark-item title="Emergency Release Notification"></app-checkmark-item>
                            <app-checkmark-item title="Hazardous Chemical Storage Reporting"></app-checkmark-item>
                            <app-checkmark-item title="Toxic Release Inventory (Tier I and II)"></app-checkmark-item>
                            <app-checkmark-item title="Form A and R"></app-checkmark-item>
                        </ul>
                    </div>
                </app-accordion-item>
                <!-- Environmental -->
                <app-accordion-item title="Environmental">
                    <div class="flex flex-col items-center justify-center">
                        <p class="p-4">
                            ACTE2S helps clients identify all federal, state, and local environmental regulatory
                            requirements applicable to a facility and to assess the current compliance status of the
                            facility.
                            Through our regulatory compliance and permitting experience, ACTE2S is well positioned to
                            help clients meet all applicable environmental regulations.
                        </p>
                    </div>
                </app-accordion-item>
                <!-- Environmental Due Dilligence -->
                <app-accordion-item title="Environmental Due Dilligence:">
                    <div class="flex flex-col items-center justify-center">
                        <ul class="space-y-0 w-full p-4 bg-slate-100 rounded">
                            <app-checkmark-item title="Environmental Screens"></app-checkmark-item>
                            <app-checkmark-item title="Records Search with Risk Assessment"></app-checkmark-item>
                            <app-checkmark-item title="Phase I Environmental Site Assessments"></app-checkmark-item>
                            <app-checkmark-item title="Phase II Environmental Site Assessments"></app-checkmark-item>
                            <app-checkmark-item title="Hazardous Materials Management"></app-checkmark-item>
                            <app-checkmark-item title="Contaminant Delineation / Remediation"></app-checkmark-item>
                            <app-checkmark-item title="Vapor Intrusion Testing"></app-checkmark-item>
                            <app-checkmark-item
                                title="Voluntary Cleanup Fund Enrollment and Management"></app-checkmark-item>
                        </ul>
                    </div>
                </app-accordion-item>
                <!-- Expert Services -->
                <app-accordion-item title="Expert Services">
                    <div class="flex-col items-center justify-center">
                        <p class="p-4">
                            <strong>Litigation Support</strong><br><br>
                            ACTE2S helps clients address liabilities and recover costs for construction, insurance,
                            water resource, and environmental related issues.
                            Our services include expert testimony, preparation of expert reports, cost allocation
                            analysis, deposition preparation for opposing experts and settlement negotiations.<br><br>
                            Approaching insurance claim support from both a technical perspective as well as a clear
                            understanding of potential insurance defenses, ACTE2S has provided expert services to As
                            Damages,
                            Owned Property, Expected and Intended, Sudden and Accidental, and Late Notice disputes.
                        </p>
                        <img class="" src="../../../assets/images/normal/NEPA-SEPA_H.png" alt="">
                    </div>
                </app-accordion-item>
                <!-- NEPA and SEPA-->
                <app-accordion-item title="NEPA and SEPA">
                    <div class="flex flex-col items-center justify-center">
                        <p class="p-4">
                            <strong>Services Include:</strong>
                        </p>
                        <ul class="space-y-0 w-full p-4 bg-slate-100 rounded">
                            <app-checkmark-item
                                title="NEPA CATEXs, EAs, EISs, NHPA Section 4(f), LAWCON Section 6(f)"></app-checkmark-item>
                            <app-checkmark-item
                                title="Wetland and Stream Delineations, Mitigation, and Permitting"></app-checkmark-item>
                            <app-checkmark-item title="Water Quality Sampling and Analyses"></app-checkmark-item>
                            <app-checkmark-item
                                title="Surface Water Quality Mitigation Strategies"></app-checkmark-item>
                            <app-checkmark-item title="Water Resource Planning and Management"></app-checkmark-item>
                            <app-checkmark-item
                                title="Stormwater Pollution Prevention Planning, Sampling, and Analyses"></app-checkmark-item>
                            <app-checkmark-item title="NHPA Section 106 Compliance"></app-checkmark-item>
                            <app-checkmark-item
                                title="Phase I and Phase II Archeological Investigations"></app-checkmark-item>
                            <app-checkmark-item title="Biological Surveys"></app-checkmark-item>
                            <app-checkmark-item
                                title="Threatened and Endangered Species and Habitat Evaluations"></app-checkmark-item>
                            <app-checkmark-item
                                title="Architectural Evaluations and NRHP Nominations"></app-checkmark-item>
                            <app-checkmark-item title="Natural Resource Conservation Plans"></app-checkmark-item>
                            <app-checkmark-item title="Forest Management Plans"></app-checkmark-item>
                        </ul>
                    </div>
                </app-accordion-item>
                <!-- Other -->
                <app-accordion-item title="Other">
                    <div class="flex flex-col items-center justify-center">
                        <ul class="space-y-0 w-full p-4 bg-slate-100 rounded">
                            <app-checkmark-item
                                title="PRP Representation (and Development of Historical Disposal Practices)"></app-checkmark-item>
                            <app-checkmark-item
                                title="	Environmental Management System Development and Implementation"></app-checkmark-item>
                            <app-checkmark-item title="Sustainable Initiatives"></app-checkmark-item>
                        </ul>
                    </div>
                </app-accordion-item>
                <!-- Our Solid and Hazardous Waste Services Include: -->
                <app-accordion-item title="Our Solid and Hazardous Waste Services Include:">
                    <div class="flex flex-col items-center justify-center">
                        <p class="p-4">
                            <strong>RCRA Generator Compliance</strong>
                        </p>
                        <ul class="space-y-0 w-full p-4 bg-slate-100 rounded">
                            <app-checkmark-item title="Hazardous Waste Management Programs"></app-checkmark-item>
                            <app-checkmark-item title="Waste Determinations"></app-checkmark-item>
                            <app-checkmark-item title="Contingency Plan Development"></app-checkmark-item>
                            <app-checkmark-item title="Emergency Evacuation Plan Development"></app-checkmark-item>
                            <app-checkmark-item title="Biennial Report Preparation"></app-checkmark-item>
                            <app-checkmark-item title="Waste Reduction Program Development"></app-checkmark-item>
                            <app-checkmark-item title="Training"></app-checkmark-item>
                        </ul>
                    </div>
                </app-accordion-item>
            </app-accordion>
        </div>
        <!-- Group 2 -->
        <div class="md:w-1/2 md:max-w-lg">
            <app-accordion>
                <!-- RCRA Permitting and Field Investigations -->
                <app-accordion-item title="RCRA Permitting and Field Investigations">
                    <div class="flex flex-col items-center justify-center">
                        <ul class="space-y-0 w-full p-4 bg-slate-100 rounded">
                            <app-checkmark-item title="TSD Auditing"></app-checkmark-item>
                            <app-checkmark-item
                                title="Part A and Part B Permit Application Preparation"></app-checkmark-item>
                            <app-checkmark-item title="RCRA Facility Investigation Work Plan"></app-checkmark-item>
                            <app-checkmark-item title="Development"></app-checkmark-item>
                            <app-checkmark-item title="Corrective Action Plan Preparation"></app-checkmark-item>
                            <app-checkmark-item title="Site Investigation and Delineation"></app-checkmark-item>
                            <app-checkmark-item title="Site Remediation and Field Services"></app-checkmark-item>
                        </ul>
                    </div>
                </app-accordion-item>
                <!-- Solid and Hazardous Waste -->
                <app-accordion-item title="Solid and Hazardous Waste">
                    <div class="flex flex-col items-center justify-center">
                        <p class="p-4">
                            ACTE2S provides a full range of solid and hazardous waste services for industrial,
                            commercial, and institutional facilities.
                            We assist clients with generator program development, including waste determinations, site
                            assessments, compliance plans, permitting, and remediation/field services.
                            Our experience and extensive interaction with state and federal agencies enables us to
                            cost-effectively address our clients’ needs.
                        </p>
                        <img class="" src="../../../assets/images/normal/Solid-Hazardous-waste.png" alt="">
                    </div>
                </app-accordion-item>
                <!-- Solid Waste -->
                <app-accordion-item title="Solid Waste">
                    <div class="flex flex-col items-center justify-center">
                        <ul class="space-y-4 w-full p-4 bg-slate-100 rounded">
                            <app-checkmark-item title="Landfill Title V Permitting"></app-checkmark-item>
                            <app-checkmark-item
                                title="Landfill Gas Sampling, NSPS Tier II Emission"></app-checkmark-item>
                            <app-checkmark-item title="Land application Permitting"></app-checkmark-item>
                        </ul>
                    </div>
                </app-accordion-item>
                <!-- Technologies -->
                <app-accordion-item title="Technologies">
                    <div class="flex flex-col items-center justify-center">
                        <ul class="space-y-4 w-full p-4 bg-slate-100 rounded">
                            <app-checkmark-item title="Source Reduction"></app-checkmark-item>
                            <app-checkmark-item title="Byproduct Reuse"></app-checkmark-item>
                            <app-checkmark-item
                                title="Separation Technologies/Diversion for Recycling"></app-checkmark-item>
                            <app-checkmark-item title="Organic Waste Digestion"></app-checkmark-item>
                            <app-checkmark-item title="Integrated Waste Management System"></app-checkmark-item>
                        </ul>
                    </div>
                </app-accordion-item>
                <!-- Underground and Aboveground Storage Tanks -->
                <app-accordion-item title="Underground and Aboveground Storage Tanks">
                    <div class="flex flex-col items-center justify-center">
                        <img class="" src="../../../assets/images/normal/UST-underground-storage-tank.jpg" alt="">
                        <p class="p-4">
                            <strong>Services Include</strong>
                        </p>
                        <ul class="space-y-4 w-full p-4 bg-slate-100 rounded">
                            <app-checkmark-item
                                title="Risk-Based Corrective Action (RBCA) Investigations (Tier 1/2/3 Site Cleanup Reports, Site Monitoring Reports)"></app-checkmark-item>
                            <app-checkmark-item
                                title="Corrective Action Design and Implementation (chemical oxidation injections, laser-induced fluorescence plume definition, soil excavation, land farm application, air sparge/soil vapor extraction systems)"></app-checkmark-item>
                            <app-checkmark-item title="Tank Closures"></app-checkmark-item>
                            <app-checkmark-item title="Free Product Monitoring and Removal"></app-checkmark-item>
                        </ul>
                    </div>
                </app-accordion-item>
                <!-- Water Quality -->
                <app-accordion-item title="Water Quality">
                    <div class="flex flex-col items-center justify-center">
                        <p class="p-4">
                            The protection of the beneficial uses of water is mandated by the Clean Water ACTE2S and is
                            paramount to the sustainability of our natural resources and economy. At ACTE2S, we offer a
                            range of water quality services to industry.
                            These services range in scope from relatively straightforward mixing zone analyses of
                            permitted outfalls where comparisons are made against water quality criteria to broader
                            scale analyses involving priority pollutants and sediment loading allocations.<br><br>
                            ACTE2S’s staff of scientists provides an interdisciplinary perspective for evaluating both
                            the potential effects of water quality contamination as well as the means to address the
                            technical challenges required to mitigate the problem.
                            As a service provider to industry, our primary focus is providing cost-effective solutions
                            for facilities that require storm water plans, oil spill prevention and response plans, and
                            National or State Pollutant Discharge Elimination System permits.<br><br>
                            <strong>Listed Below Are Services Provided by ACTE2S:</strong>
                        </p>
                        <img class="" src="../../../assets/images/normal/Water-quality_H.png" alt="">
                        <ul class="space-y-0 w-full p-4 bg-slate-100 rounded">
                            <app-checkmark-item
                                title="Spill Prevention Control and Countermeasure Plans (SPCC)"></app-checkmark-item>
                            <app-checkmark-item
                                title="Storm Water Pollution Prevention Plans (SWPPP)"></app-checkmark-item>
                            <app-checkmark-item
                                title="Pollution Prevention Opportunity Assessments"></app-checkmark-item>
                            <app-checkmark-item title="Facility Response Plans (FRP)"></app-checkmark-item>
                            <app-checkmark-item title="NPDES Permit Applications and renewals"></app-checkmark-item>
                            <app-checkmark-item title="Training Programs"></app-checkmark-item>
                            <app-checkmark-item
                                title="Negotiations of Permit Conditions With Regulating Agency"></app-checkmark-item>
                            <app-checkmark-item title="Water Resource Evaluation"></app-checkmark-item>
                            <app-checkmark-item title="GIS Mapping and Integrated Databases"></app-checkmark-item>
                            <app-checkmark-item
                                title="Groundwater Hydrology – Water Usage Permitting"></app-checkmark-item>
                            <app-checkmark-item title="Best Management Practices and Plans"></app-checkmark-item>
                        </ul>
                    </div>
                </app-accordion-item>
                <!-- What is an Environmental Audit? -->
                <app-accordion-item title="What is an Environmental Audit?">
                    <div class="flex flex-col items-center justify-center">
                        <p class="p-4">
                            Environmental auditing is a systematic, documented, periodic and objective process in
                            assessing an organization’s activities and services in relation to:
                        </p>
                        <ul class="space-y-0 w-full p-4 bg-slate-100 rounded">
                            <app-checkmark-item
                                title="Assessing compliance with relevant statutory and internal requirements"></app-checkmark-item>
                            <app-checkmark-item
                                title="Facilitating management control of environmental practices"></app-checkmark-item>
                            <app-checkmark-item title="Promoting good environmental management"></app-checkmark-item>
                            <app-checkmark-item title="Maintaining credibility with the public"></app-checkmark-item>
                            <app-checkmark-item
                                title="Raising staff awareness and enforcing commitment to departmental environmental policy"></app-checkmark-item>
                            <app-checkmark-item title="Exploring improvement opportunities"></app-checkmark-item>
                        </ul>
                    </div>
                </app-accordion-item>
            </app-accordion>
        </div>
    </div>
</div>