<nav class="bg-white text-gray p-5 shadow-lg">
    <div class="flex items-center justify-between">
        <!-- Logos -->
        <div class="flex space-x-4">
            <!-- ACTE2S Logo -->
            <a routerLink="/home"><img class="h-14" src="../../assets/logo/ACTE2S-logo.svg" alt=""></a>
            <!-- Vet Owned Logo -->
            <img class="hidden sm:inline max-h-14" src="../../assets/certs/vet-own-vet-strong.png" alt="">
        </div>
        <!-- Contact Items -->
        <div class="flex space-x-4 items-center">
            <!-- Phone -->
            <div class="hidden lg:inline">
                <img class="inline mr-2" src="../../assets/icons/phone.svg" alt="" width="20" height="20">
                <a href="tel:+18772283274" target="_self" aria-label="Go to tel:+18772283274">(877) ACTE2S-4</a>
                <a href="https://www.facebook.com/profile.php?id=100089087146543" target="_blank"><img
                        class="inline ml-2" src="../../assets/icons/facebook.svg" alt="" width=40 height="40"></a>
                <a href="https://www.linkedin.com/company/acte2s" target="_blank"><img class="inline ml-2"
                        src="../../assets/icons/linkedin.svg" alt="" width=40 height="40"></a>
            </div>
            <!-- Learn More Button -->
            <app-cta-button class="max-[920px]:hidden" title="Learn More"
                (buttonClick)="handleLearnMoreButtonClick($event)"></app-cta-button>
            <!-- Hamburger Menu -->
            <button class="hidden max-[920px]:inline" (click)="toggleDrawer()"><img src="../../assets/icons/bars.svg"
                    alt="" width="20" height="20"></button>
        </div>

    </div>
    <!-- Navigation Links -->
    <div class="mt-4 max-[920px]:hidden">
        <ul class="flex">
            <!-- Services Dropdown -->
            <li class="relative group">
                <div class="p-2 flex items-center text-appBlue group-hover:text-appRed font-medium">
                    <a routerLink="/home" class="flex items-center space-x-1">
                        <span>Services</span>
                        <svg class="w-4 h-4 fill-appBlue hover:fill-appRed group-hover:fill-appRed"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                            <path
                                d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                        </svg>
                    </a>
                </div>
                <!-- Dropdown Menu -->
                <ul class="absolute hidden bg-white shadow-lg group-hover:block min-w-48 z-10">
                    <li><a routerLink="/environmental"
                            class="px-4 py-2 block hover:bg-slate-100 hover:text-appRed">Environmental</a></li>
                    <li><a routerLink="/engineering"
                            class="px-4 py-2 block hover:bg-slate-100 hover:text-appRed">Engineering</a>
                    </li>
                    <li><a routerLink="/health-safety"
                            class="px-4 py-2 block hover:bg-slate-100 hover:text-appRed">Occupational
                            Safety</a>
                    </li>
                </ul>
            </li>
            <!-- Capabilities -->
            <li><a routerLink="/capabilities"
                    class="p-2 inline-block font-medium text-appBlue hover:text-appRed">Capabilities</a>
            </li>
            <!-- Past Performance -->
            <li><a routerLink="/past-performance"
                    class="p-2 inline-block font-medium text-appBlue hover:text-appRed">Past
                    Performance</a></li>
            <!-- Project Media -->
            <li><a routerLink="/project-media"
                    class="p-2 inline-block font-medium text-appBlue hover:text-appRed">Project
                    Media</a>
            </li>
            <!-- About Us -->
            <li><a routerLink="/about-us" class="p-2 inline-block font-medium text-appBlue hover:text-appRed">About
                    Us</a>
            </li>
            <!-- Contact Us -->
            <li><a routerLink="/contact-us" class="p-2 inline-block font-medium text-appBlue hover:text-appRed">Contact
                    Us</a>
            </li>
            <!-- Joint Ventures Dropdown -->
            <li class="relative group">
                <div class="p-2 flex items-center text-appBlue group-hover:text-appRed font-medium">
                    <a routerLink="/home" class="flex items-center space-x-1">
                        <span>Joint Ventures</span>
                        <svg class="w-4 h-4 fill-appBlue hover:fill-appRed group-hover:fill-appRed"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                            <path
                                d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                        </svg>
                    </a>
                </div>
                <!-- Dropdown Menu -->
                <ul class="absolute hidden bg-white shadow-lg group-hover:block min-w-48 z-10">
                    <li><a href="https://www.theblackledgegroup.com" target="_blank"
                            class="px-4 py-2 block hover:bg-slate-100 hover:text-appRed">The Blackledge Group</a>
                    </li>
                    <li><a href="tel:8002410676" class="px-4 py-2 block hover:bg-slate-100 hover:text-appRed">(800)
                            241-0676</a></li>
                </ul>
            </li>
            <!-- Careers Dropdown -->
            <li class="relative group">
                <div class="p-2 flex items-center text-appBlue group-hover:text-appRed font-medium">
                    <a routerLink="/home" class="flex items-center space-x-1">
                        <span>Careers</span>
                        <svg class="w-4 h-4 fill-appBlue hover:fill-appRed group-hover:fill-appRed"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                            <path
                                d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                        </svg>
                    </a>
                </div>
                <!-- Dropdown Menu -->
                <ul class="absolute hidden bg-white shadow-lg group-hover:block min-w-36 z-10">
                    <li><a routerLink="/careers" class="px-4 py-2 block hover:bg-slate-100 hover:text-appRed">Career
                            Paths</a>
                    </li>
                    <li><a href="https://www.indeed.com/cmp/Act-Safe,-LLC" target="_blank"
                            class="px-4 py-2 block hover:bg-slate-100 hover:text-appRed">Apply Now</a></li>
                </ul>
            </li>
        </ul>
    </div>
</nav>

<!-- Drawer Overlay -->
<div class="fixed inset-0 bg-black bg-opacity-50 z-40" *ngIf="isDrawerOpen" (click)="toggleDrawer()"></div>

<!-- Navigation Drawer -->
<div class="fixed top-0 right-0 bg-white shadow-lg text-black w-64 h-full z-50 transform transition-transform"
    [class.translate-x-full]="!isDrawerOpen" [class.translate-x-0]="isDrawerOpen">
    <!-- Close Button -->
    <button class="absolute top-4 right-4 bg-appRed-500 hover:bg-appRed-600 text-black font-bold p-2 rounded-full"
        (click)="toggleDrawer()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
            class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
    </button>
    <!-- Links -->
    <ul class="space-y-4 mt-8 px-4" (click)="toggleDrawer()">
        <!-- Home -->
        <li>
            <a routerLink="/home" class="font-bold text-appBlue hover:text-appRed">Home</a>
        </li>
        <!-- Services Parent -->
        <li>
            <p class="font-bold text-appBlue hover:text-appRed">Services</p>
            <ul class="pl-4">
                <li><a routerLink="/environmental" class="block py-1 hover:text-appRed">Environmental</a></li>
                <li><a routerLink="/engineering" class="block py-1 hover:text-appRed">Engineering</a></li>
                <li><a routerLink="/health-safety" class="block py-1 hover:text-appRed">Occupational Safety and
                        Health</a>
                </li>
            </ul>
        </li>
        <!-- Capabilities -->
        <li>
            <a routerLink="/capabilities" class="font-bold text-appBlue hover:text-appRed">Capabilities</a>
        </li>
        <!-- Past Performance -->
        <li>
            <a routerLink="/past-performance" class="font-bold text-appBlue hover:text-appRed">Past Performance</a>
        </li>
        <!-- Project Media -->
        <li>
            <a routerLink="/project-media" class="font-bold text-appBlue hover:text-appRed">Project Media</a>
        </li>
        <!-- About Us -->
        <li>
            <a routerLink="/about-us" class="font-bold text-appBlue hover:text-appRed">About Us</a>
        </li>
        <!-- Contact Us -->
        <li>
            <a routerLink="/contact-us" class="font-bold text-appBlue hover:text-appRed">Contact Us</a>
        </li>
        <!-- Joint Ventures -->
        <li>
            <p class="font-bold text-appBlue hover:text-appRed">Joint Ventures</p>
            <ul class="pl-4">
                <li><a href="https://www.theblackledgegroup.com/" target="_blank"
                        class="block py-1 hover:text-appRed">The
                        Blackledge Group</a></li>
                <li><a href="tel:8002410676" class="block py-1 hover:text-appRed">(800) 241-0676</a></li>
            </ul>
        </li>
        <!-- Careers Parent -->
        <li>
            <p class="font-bold text-appBlue hover:text-appRed">Careers</p>
            <ul class="pl-4">
                <li><a routerLink="/careers" class="block py-1 hover:text-appRed">Career Paths</a></li>
                <li><a href="https://www.indeed.com/cmp/Act-Safe,-LLC" target="_blank"
                        class="block py-1 hover:text-appRed">Apply Now</a></li>
            </ul>
        </li>
    </ul>
</div>