import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CtaButtonComponent } from '../components/cta-button/cta-button.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule, RouterModule, CtaButtonComponent],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.css'
})
export class FooterComponent {
  handleBrochureButtonClick(event: Event){
    console.log(event)
  }
}
