<!-- Parent -->
<div class="flex flex-col">
    <!-- Header -->
    <div class="mt-2 font-[Oswald] font-bold text-center max-2-5xl sm:mt-4 sm:p-4">
        <h1 class="text-4xl text-appBlue">View Some of Our Previous</h1>
        <h2 class="text-4xl text-appRed">Compliance Projects</h2>
    </div>
    <div class="fbg-white  mt-10 m-2 flex flex-col justify-center md:flex-row md:space-x-4 md:m-5 z-10">
        <div class="md:w-1/2 md:max-w-lg">
            <app-accordion>
                <!-- Environmental Remediation Accordion -->
                <app-accordion-item title="Environmental Remediation">
                    <p class="space-y-0 w-full p-4 bg-slate-100 rounded">
                        <strong>Project Description:</strong> In June 2016 and 2017, two diesel spills occurred with an
                        approximate combined release of 500 gallons. In December 2018 ACT was contracted (Prime) to
                        prepare a Site Assessment Report Addendum (SARA). ACT performed monthly Free Product Recovery
                        and Quarterly Interim Source Removal. In July 2022 ACT submitted a Limited Scope Remedial Action
                        Plan (LSRAP). Upon approval by the State Department of Environmental Protection ACT performed
                        environmental remediation in February 2023. The photos depict project progress as we move toward
                        a clean site, well abandonment and closure.
                    </p>
                </app-accordion-item>
            </app-accordion>
        </div>
        <!-- Carousel -->
        <div class="mt-4 mb-4 md:mt-0 md:w-1/2 md:max-w-lg">
            <app-carousel></app-carousel>
        </div>
    </div>
</div>