<!-- Parent -->
<div class="flex flex-col">
    <!-- Header -->
    <div class="mt-2 font-[Oswald] font-bold text-center max-2-5xl sm:mt-4 sm:p-4">
        <h1 class="text-4xl text-appBlue">Engineering and</h1>
        <h2 class="text-4xl text-appRed">Construction Solutions for Your Project</h2>
    </div>
    <!-- Accordions -->
    <div class="bg-white m-2 flex flex-col justify-center md:flex-row md:space-x-4 md:m-5 z-10">
        <!-- Group 1 -->
        <div class="md:w-1/2 md:max-w-lg">
            <app-accordion>
                <!-- Construction Drawings and Specifications -->
                <app-accordion-item title="Construction Drawings and Specifications">
                    <div class="flex flex-col items-center justify-center">
                        <p class="p-4">
                            ACTE2S provides final working drawings and specifications for the Work, issued by Engineer
                            and describing the size, character, design, construction, materials, finishes, and
                            structural, mechanical, electrical and other systems of the Project.
                        </p>
                    </div>
                </app-accordion-item>
                <!-- Construction Inspections -->
                <app-accordion-item title="Construction Inspections">
                    <div class="flex flex-col items-center justify-center">
                        <p class="p-4">
                            ACTE2S is your one stop shop for construction / project management services, cybersecurity,
                            engineering, procurement, and operator qualification training. We provide the highest
                            quality contract administrators, construction inspectors, information managers, engineers,
                            draftsmen, procurement, Environmental and / or Safety staff, and training.
                        </p>
                    </div>
                </app-accordion-item>
                <!-- Construction, Program & Project Management -->
                <app-accordion-item title="Construction, Program & Project Management">
                    <div class="flex flex-col items-center justify-center">
                        <p class="p-4">
                            ACTE2S offers end-to-end client experience that includes seamless communication, budgeting,
                            staffing, on-site organization, and solid, quality every time.
                            Because of our broad construction experience, we are able to offer a wide array of
                            Construction Services. We provide full Construction Management Services or any component
                            thereof.
                            Primary Construction Planning, Management & Administration services include:
                        </p>
                        <ul class="space-y-0 w-full p-4 bg-slate-100 rounded">
                            <app-checkmark-item
                                title="Defining issues and goals, setting objectives, planning and implementing strategy"></app-checkmark-item>
                            <app-checkmark-item
                                title="Coordinating the project staff and on-site activities"></app-checkmark-item>
                            <app-checkmark-item
                                title="Organizing and overseeing job-site construction administration and supervision"></app-checkmark-item>
                            <app-checkmark-item
                                title="Managing the procurement of contractors and suppliers"></app-checkmark-item>
                            <app-checkmark-item
                                title="Managing schedules, quality of work, and trades and payment requests"></app-checkmark-item>
                            <app-checkmark-item
                                title="Developing, monitoring, updating and communicating the schedule and its progress"></app-checkmark-item>
                            <app-checkmark-item
                                title="Establishing and maintaining communication of project with owner, architect, and contractors"></app-checkmark-item>
                            <app-checkmark-item
                                title="Applying construction management tools and techniques for successful project completion"></app-checkmark-item>
                        </ul>
                    </div>
                </app-accordion-item>
                <!-- Facility Condition Assessment (FCAs) -->
                <app-accordion-item title="Facility Condition Assessment (FCAs)">
                    <div class="flex flex-col items-center justify-center">
                        <p class="p-4">
                            ACTE2S typically prepares the FCA for owners or managers of real estate portfolios to help
                            optimize and maintain the physical condition and value of their assets, develop capital
                            budgets, and prioritize resources.<br><br>
                            A <strong>Facility Condition Assessment</strong> can also be used to secure additional
                            funding for renovations.
                        </p>
                        <ul class="space-y-0 w-full p-4 bg-slate-100 rounded">
                            <app-checkmark-item
                                title="We can help with Property Condition Reports by a professional licensed engineer in compliance with ASTM E2018-15 Standards."></app-checkmark-item>
                            <app-checkmark-item
                                title="We provide an unbiases opinion on a building’s current conditions by qualified and experience building inspectors."></app-checkmark-item>
                        </ul>
                    </div>
                </app-accordion-item>
            </app-accordion>
        </div>
        <!-- Group 2 -->
        <div class="md:w-1/2 md:max-w-lg">
            <app-accordion>
                <!-- Independent Design Reviews -->
                <app-accordion-item title="Independent Design Reviews">
                    <div class="flex flex-col items-center justify-center">
                        <p class="p-4">
                            Our architectural team specializes in a diverse spectrum of projects including residential,
                            commercial, historic development, accessibility, aging in place, building energy, and
                            technology consulting.
                        </p>
                    </div>
                </app-accordion-item>
                <!-- Survey and Mapping -->
                <app-accordion-item title="Survey and Mapping">
                    <div class="flex flex-col items-center justify-center">
                        <p class="p-4">
                            ACTE2S provides a wide range of services to include:
                        </p>
                        <ul class="space-y-0 w-full p-4 bg-slate-100 rounded">
                            <app-checkmark-item title="As-Built Survey"></app-checkmark-item>
                            <app-checkmark-item title="Boundary Survey"></app-checkmark-item>
                            <app-checkmark-item title="Construction / Stakeout and Layout"></app-checkmark-item>
                            <app-checkmark-item title="FEMA / Flood / LOMA Letters"></app-checkmark-item>
                            <app-checkmark-item title="Final Survey"></app-checkmark-item>
                            <app-checkmark-item title="Route Surveys"></app-checkmark-item>
                            <app-checkmark-item title="Legal Description"></app-checkmark-item>
                            <app-checkmark-item title="Platting Services"></app-checkmark-item>
                            <app-checkmark-item title="Survey Sketch"></app-checkmark-item>
                            <app-checkmark-item title="Wetland Delineation"></app-checkmark-item>
                        </ul>
                    </div>
                </app-accordion-item>
                <!-- Waterfront Engineering-->
                <app-accordion-item title="Waterfront Engineering">
                    <div class="flex flex-col items-center justify-center">
                        <p class="p-4">
                            ACTE2S's waterfront engineering professionals provide services to a wide variety of clients
                            that include municipal, state, and federal governmental agencies; commercial and
                            residential. As a leader in the design, permitting,
                            and construction administration of waterfront development, we have substantial experience
                            managing large marine construction activities with an emphasis on navigating the complex
                            permitting process associated with
                            the sensitive waterfront environments and specifics of coastal regulatory issues.<br><br>
                            <strong>Shoreline Surveys with Engineered Solutions</strong><br><br>
                            <strong>Site Evaluation</strong><br><br>
                            ACTE2S will be independently evaluated by a licensed professional engineer who will access
                            the current condition of your property, development a conceptual solution and provide that
                            information to our team who will
                            then begin to engineer a solution to remedy to problem.<br><br>
                            <strong>Engineering, Design and Permitting</strong><br><br>
                            Once the site has been evaluated, our engineering team will design a practical solution will
                            then be transformed into a site development plan which detail the existing site conditions,
                            a proposed site solution and general construction specifications that will be unique to your
                            property and situation. After the client has reviewed and approved the design plans, the
                            project will progress into the permitting phase.
                            ACTE2S will prepare all of the necessary applications for all governing agencies and work
                            through the permit process to ultimate approval.<br><br>
                            <strong>Services</strong>
                        </p>
                        <ul class="space-y-0 w-full p-4 bg-slate-100 rounded">
                            <app-checkmark-item title="Structural Evaluation, Analysis & Design"></app-checkmark-item>
                            <app-checkmark-item title="Structural Rehabilitation Design"></app-checkmark-item>
                            <app-checkmark-item
                                title="Structural Inspections & Condition Damage Assessment"></app-checkmark-item>
                            <app-checkmark-item title="Waterfront Infrastructure Design"></app-checkmark-item>
                            <app-checkmark-item title="Marina & Boat Ramp Design"></app-checkmark-item>
                            <app-checkmark-item title="Seawall & Bulkhead Design"></app-checkmark-item>
                            <app-checkmark-item
                                title="Docks, Piers, Platforms & Breakwater Design"></app-checkmark-item>
                            <app-checkmark-item
                                title="Shoreline Stabilization & Beach Development"></app-checkmark-item>
                            <app-checkmark-item title="Ferry & Ship Terminals"></app-checkmark-item>
                            <app-checkmark-item
                                title="FEMA Surveys & Flood Elevation Certification"></app-checkmark-item>
                            <app-checkmark-item title="Riparian, Lease & License Investigation"></app-checkmark-item>
                            <app-checkmark-item title="Waterfront Permitting"></app-checkmark-item>
                            <app-checkmark-item title="Wetlands Delineation, Mapping & Mitigation"></app-checkmark-item>
                            <app-checkmark-item title="Conceptual Planning"></app-checkmark-item>
                            <app-checkmark-item title="Marine Engineering"></app-checkmark-item>
                            <app-checkmark-item title="Wave Analysis"></app-checkmark-item>
                            <app-checkmark-item title="Storm Damage Assessment & Repair Design"></app-checkmark-item>
                            <app-checkmark-item title="Grants & Funding"></app-checkmark-item>
                            <app-checkmark-item title="Construction Administration & Inspection"></app-checkmark-item>
                        </ul>
                    </div>
                </app-accordion-item>
            </app-accordion>
        </div>
    </div>
</div>