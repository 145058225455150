import { Component } from '@angular/core';
import { CarouselComponent } from '../../components/carousel/carousel.component';
import { AccordionComponent } from '../../components/accordion/accordion.component';
import { AccordionItemComponent } from '../../components/accordion/accordion-item.component';

@Component({
  selector: 'app-project-media-page',
  standalone: true,
  imports: [ CarouselComponent, AccordionComponent, AccordionItemComponent ],
  templateUrl: './project-media-page.component.html',
  styleUrl: './project-media-page.component.css'
})
export class ProjectMediaPageComponent {

}
