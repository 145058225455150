import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private themeColors: { [key: string]: string } = {
    appBlue: '#113C5C',
    red: '#A82024',
  };

  getColor(
    colorName: string,
    defaultColor: string = this.themeColors['appBlue']
  ): string {
    return this.themeColors[colorName] || defaultColor;
  }
}
