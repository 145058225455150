<!-- Parent -->
<div class="flex flex-col">
    <!-- Header -->
    <div class="mt-2 font-[Oswald] text-center max-2-5xl sm:mt-4 sm:p-4">
        <h1 class="text-4xl text-appBlue font-bold">Dedicated to Providing Dependable</h1>
        <h2 class="text-4xl text-appRed font-bold">Compliance Services</h2>
        <h3 class="text-2xl mt-6 text-black font-bold">Core Competencies</h3>
        <p class="mb-4">The ACTE2S team specializes in the top workplace compliance and training service challenges that
            affect industries today.</p>
    </div>
    <!-- Link Buttons -->
    <div class="flex justify-center mb-20">
        <div
            class="mx-2 max-w-4xl grid grid-cols-2 grid-rows-8 gap-2 sm:grid-cols-3 sm:grid-rows-5 md:grid-cols-5 md:grid-rows-3">
            <div class="flex justify-center items-center">
                <a href="../../../assets/pdfs/ACM-and-LBP.pdf" target="_blank"
                    class="bg-appBlue hover:bg-appRed text-white font-bold py-6 rounded w-48 text-center">
                    ACM LBP
                </a>
            </div>
            <div class="flex justify-center items-center">
                <a href="../../../assets/pdfs/Confined-Space.pdf" target="_blank"
                    class="bg-appBlue hover:bg-appRed text-white font-bold py-6 rounded w-48 text-center">
                    Confined Space
                </a>
            </div>
            <div class="flex justify-center items-center">
                <a href="../../../assets/pdfs/Emergency-Response-Trng.pdf" target="_blank"
                    class="bg-appBlue hover:bg-appRed text-white font-bold py-3 rounded w-48 text-center">
                    Emergency Response Trng
                </a>
            </div>
            <div class="flex justify-center items-center">
                <a href="../../../assets/pdfs/Engineering-CS-09-2022-002.pdf" target="_blank"
                    class="bg-appBlue hover:bg-appRed text-white font-bold py-6 rounded w-48 text-center">
                    Engineering
                </a>
            </div>
            <div class="flex justify-center items-center">
                <a href="../../../assets/pdfs/Envtl-Remediation.pdf" target="_blank"
                    class="bg-appBlue hover:bg-appRed text-white font-bold py-6 rounded w-48 text-center">
                    Envtl Remediation
                </a>
            </div>
            <div class="flex justify-center items-center">
                <a href="../../../assets/pdfs/Envtl-Services.pdf" target="_blank"
                    class="bg-appBlue hover:bg-appRed text-white font-bold py-6 rounded w-48 text-center">
                    Envtl Services
                </a>
            </div>
            <div class="flex justify-center items-center">
                <a href="../../../assets/pdfs/Industrial-Hygiene.pdf" target="_blank"
                    class="bg-appBlue hover:bg-appRed text-white font-bold py-6 rounded w-48 text-center">
                    Industrial Hygiene
                </a>
            </div>
            <div class="flex justify-center items-center">
                <a href="../../../assets/pdfs/JHA-JSA.pdf" target="_blank"
                    class="bg-appBlue hover:bg-appRed text-white font-bold py-6 rounded w-48 text-center">
                    JHA JSA
                </a>
            </div>
            <div class="flex justify-center items-center">
                <a href="../../../assets/pdfs/Lockout-Tagout.pdf" target="_blank"
                    class="bg-appBlue hover:bg-appRed text-white font-bold py-6 rounded w-48 text-center">
                    Lockout Tagout
                </a>
            </div>
            <div class="flex justify-center items-center">
                <a href="../../../assets/pdfs/OSHA-Services.pdf" target="_blank"
                    class="bg-appBlue hover:bg-appRed text-white font-bold py-6 rounded w-48 text-center">
                    OSHA Services
                </a>
            </div>
            <div class="flex justify-center items-center">
                <a href="../../../assets/pdfs/OSHA-Training.pdf" target="_blank"
                    class="bg-appBlue hover:bg-appRed text-white font-bold py-6 rounded w-48 text-center">
                    OSHA Training
                </a>
            </div>
            <div class="flex justify-center items-center">
                <a href="../../../assets/pdfs/PFAS.pdf" target="_blank"
                    class="bg-appBlue hover:bg-appRed text-white font-bold py-6 rounded w-48 text-center">
                    PFAS
                </a>
            </div>
            <div class="flex justify-center items-center">
                <a href="../../../assets/pdfs/SPCC.pdf" target="_blank"
                    class="bg-appBlue hover:bg-appRed text-white font-bold py-6 rounded w-48 text-center">
                    SPCC
                </a>
            </div>
            <div class="flex justify-center items-center">
                <a href="../../../assets/pdfs/AST_UST-CS-10-2022.pdf" target="_blank"
                    class="bg-appBlue hover:bg-appRed text-white font-bold py-6 rounded w-48 text-center">
                    UST/AST
                </a>
            </div>
            <div class="flex justify-center items-center">
                <a href="../../../assets/pdfs/Waterfront-Engineering.pdf" target="_blank"
                    class="bg-appBlue hover:bg-appRed text-white font-bold py-6 sm:py-6 md:py-3 rounded w-48 text-center">
                    Waterfront Engineering
                </a>
            </div>
        </div>
    </div>
</div>