import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';


@Component({
  standalone: true,
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'], // You can create a .scss file for your styles
  imports: [CommonModule],
})
export class CarouselComponent implements OnInit {
  images: string[] = [
    '../../assets/images/carousel/20181031_115452-scaled.jpg',
    '../../assets/images/carousel/20181031_120743-1-scaled.jpg',
    '../../assets/images/carousel/20181031_123618-1-scaled.jpg',
    '../../assets/images/carousel/20181031_0903270-scaled.jpg',
    '../../assets/images/carousel/IMG_2784-1-scaled.jpg',
    '../../assets/images/carousel/IMG_2787-scaled.jpg',
    '../../assets/images/carousel/IMG_2808-scaled.jpg',
    '../../assets/images/carousel/IMG_2811-scaled.jpg',
    '../../assets/images/carousel/IMG_2853-scaled.jpg',
    '../../assets/images/carousel/IMG_2856-scaled.jpg',
    '../../assets/images/carousel/IMG_2864-scaled.jpg',
    '../../assets/images/carousel/IMG_2875-scaled.jpg',
    '../../assets/images/carousel/IMG_2887-scaled.jpg',
    '../../assets/images/carousel/IMG_2893-scaled.jpg',
    '../../assets/images/carousel/IMG_2908-scaled.jpg',
    '../../assets/images/carousel/IMG_2918-1-scaled.jpg',
    '../../assets/images/carousel/IMG_2921-scaled.jpg',
    '../../assets/images/carousel/IMG_2933-scaled.jpg',
    '../../assets/images/carousel/Photo-Jan-21-2023-8-27-25-AM-scaled.jpg',
    '../../assets/images/carousel/Photo-Jan-21-2023-11-59-27-AM-scaled.jpg',
    '../../assets/images/carousel/Photo-Jan-23-2023-9-40-50-AM-scaled.jpg',
    '../../assets/images/carousel/Photo-Jan-26-2023-1-31-11-PM-scaled.jpg',
    '../../assets/images/carousel/Photo-Jan-26-2023-1-33-17-PM-scaled.jpg',
    
  ];

  activeIndex = 0;

  ngOnInit(): void {
    this.startAutoPlay();
  }

  startAutoPlay(): void {
    setInterval(() => {
      this.nextImage();
    }, 5000); // Change this value for the auto-slide interval (in milliseconds)
  }

  nextImage(): void {
    this.activeIndex = (this.activeIndex + 1) % this.images.length;
  }

  prevImage(): void {
    this.activeIndex = (this.activeIndex - 1 + this.images.length) % this.images.length;
  }
}
