import { Career } from './career.model';

const constructionSafetySpecialist: Career = {
  title: 'CONSTRUCTION SAFETY SPECIALIST',
  summary:
    "Currently hiring Construction Safety Specialist. Overall knowledge in safety, OSHA requirements & standards. \
    Good verbal and written skills will be required. Employee will work 50% of their time on client's property. Veterans strongly encouraged to apply.",
  duties: [
    'Provide safety and health assistance to management, supervisors, employees and collateral duty safety representatives.',
    'Perform safety audits, inspections and evaluations to identify potential hazards and implement corrective actions in accordance with regulatory requirements.',
    'Provide specialty safety services and assessments addressing requirements that include lockout tagout and confined space.',
    'Develop and deliver customer training one-on-one, in groups - classrooms.',
    'Investigating complaints and reports of unsafe or unhealthful working conditions; provide recommendations for abatement actions; and verify completion of abatement actions.',
  ],
  requirements: [
    'Four-year degree from an accredited university preferred, or equal years of experience.',
    'Demonstrated success in a fast-paced environment',
    'Communicative team player that is very detail oriented and has outstanding interpersonal skills',
    'Demonstrated ability to multitask in a complex environment',
    'Versatility, flexibility, and a willingness to work within constantly changing priorities with enthusiasm',
    'Organizational skills and multitasking abilities',
    'Creative, flexible, and innovative team player',
    'Strong computer skills preferably in a PC environment',
  ],
};

const environmentalFieldTechnician: Career = {
  title: 'ENVIRONMENTAL FIELD TECHNICIAN',
  summary:
    'The Candidate will assist and perform various field and office functions from site inspections (for due diligence projects) to field sampling activities and assist with proposal/budgeting development and report writing on projects focused in the Federal Market.',
  duties: [
    'Environmental reviews',
    'State and federal regulatory program file reviews',
    'Site investigations',
    'Performance of environmental due diligence projects',
    'Maintaining a presence in the Office to represent the company during others field duties',
  ],
  requirements: [
    'Ability to obtain certifications and training for various hazardous materials topics',
    'Ability to perform manual task in hazardous environments',
    'Excellent writing, organizational, time management and multi-tasking skills',
    'Personable and able to communicate with people at all levels',
    '<strong>Willingness to travel as necessary</strong>',
    'Must be a self-starter, resourceful, and have the ability to work productively on multiple projects at a time under strict deadlines with guidance from senior staff',
    'State regulatory knowledge is a plus',
    'Proficiency with Microsoft Office, Adobe Acrobat preferred',
    "Bachelor's degree in environmental science or natural sciences field required",
    'The work environment will be both indoors and outdoors. Indoor work takes place in a typical office setting. The noise level in the work environment is usually moderate. Outdoor work requires the ability to effectively perform work in all types of weather conditions.',
    'While performing the duties of this job, the employee is regularly required to sit; use hands and fingers to manipulate, handle or reach; and talk or hear. The employee may occasionally be required to stand, walk, balance, kneel or crouch. The employee may be required to lift and/or move up to 125 pounds. Specific vision abilities required by this job include close vision and the ability to focus.',
    "(preferred) A Bachelor's degree in related field (e.g., environmental science, natural sciences)",
    'Strong working knowledge of Microsoft Office software',
    'Excellent computer and organizational skills, as well as attention to detail',
    'Excellent written and verbal communication and interpersonal skills',
    'Ability to meet deadlines',
  ],
};

const federalContractWriter: Career = {
  title: 'FEDERAL CONTRACT WRITING',
  summary:
    'Applicants must have previous Federal contract writing experience in both the Environmental and Construction fields.',
  duties: [
    'Proposal Writer to write responses to Federal Government RFIs, RFPs at the IDIQ and Task Order level, and other responses at the contract program level as needed. The candidate will work closely with assigned technical staff, SMEs (employees and consultants), and proposal and executive management.',
    'Research, write, and edit technical material for use in proposal development activities',
    'Author a variety of proposal sections, including technical approaches, management plans, and other content, and integrate proposal content authored by other writers into cohesive, compliant technical proposals',
    'Work with subject matter experts to prepare original content for proposals and/or review and revise text prepared by experts',
    'Coordinate proposal efforts and new business development by utilizing standardized organizational best practices to create quality proposal products aimed at achieving corporate business development goals',
    "Assist and organize the business development team's initiatives for all new business opportunities from pre-marketing through proposal submission",
    'Contribute to corporate proposal development standard operating procedures by analyzing and evaluating proposal best practices',
    'Participate in developing and generating strategies, themes, and discriminators for responses',
    'Design or suggest graphics for written proposal material',
    'Review and contribute to proposal outlines and compliance matrices',
    'Participate in content review sessions',
    'Review and edit final text for grammar, tone, style, clarity, and consistency',
    'Participates in advancing drafts throughout the writing process',
    'Incorporates appropriate comments in collaboration with lead(s), SMEs, and proposal management',
    'Supports final proposal production as required',
    'May write, review, and/or edit other corporate documents as requested.',
  ],
  requirements: [
    "Bachelor's degree in English or other related discipline",
    "Minimum 3 years' experience writing and editing Federal Government proposals and marketing materials",
    'Experience writing reports, briefs, and similar content',
    "Knowledge of the Federal Government's acquisition process",
    'Strong knowledge of Microsoft Word, PowerPoint and Adobe Acrobat',
    'Experience using GovWin & FedBizOpps',
    'Ability to work under tight deadlines and on multiple documents',
    'Highly organized, proactive, and collaborative',
    'Must have excellent interpersonal, verbal and written communication skills',
    'Ability to meet milestones and deadlines',
    'Must offer reasonable schedule flexibility to meet proposal requirements',
    'Proposal graphics experience a plus',
  ],
};

export const careers: Career[] = [
  constructionSafetySpecialist,
  environmentalFieldTechnician,
  federalContractWriter,
];
