<h2 class="text-3xl text-appBlue font-[Oswald] text-center my-3 lg:my-8">Careers</h2>
<!-- Columns -->
<div class="max-w-5xl mx-auto columns-1 gap-4 md:columns-2 space-y-4 p-4">

    <!-- Careers -->
    <ng-container *ngFor="let career of careers">

        <!-- Career Card -->
        <div class="break-inside-avoid flex flex-col bg-greyCard p-4 space-y-4 shadow-lg">
            <!-- Overview -->
            <div class="flex flex-col space-y-2">
                <h3 class="text-2xl text-appBlue font-[Oswald]">{{ career.title }}</h3>
                <p>{{ career.summary }}</p>
            </div>
            <!-- Duties and Responsibilities -->
            <div class="flex flex-col space-y-2">
                <h3 class="text-lg text-appBlue font-[Oswald]">DUTIES AND RESPONSIBILITIES:</h3>
                <ul class="list-disc pl-5 space-y-2">
                    <li *ngFor="let duty of career.duties" [innerHTML]="duty"></li>
                </ul>
            </div>
            <!-- Required Skills and Experience -->
            <div class="flex flex-col space-y-2">
                <h3 class="text-lg text-appBlue font-[Oswald]">REQUIRED SKILLS AND EXPERIENCE:</h3>
                <ul class="list-disc pl-5 space-y-2">
                    <li *ngFor="let requirement of career.requirements" [innerHTML]="requirement"></li>
                </ul>
            </div>
        </div>

    </ng-container>

    <app-career-form></app-career-form>

</div>