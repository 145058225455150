<div [ngClass]="{ 'accordion-header': 'true', 'accordion-header-open': showBody }" (click)="toggle()">
    <div class="header-title select-none">
        {{ title }}
    </div>
    <div *ngIf="!showBody" class="w-6 h-6">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
            fill="#fff"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
            <path
                d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
        </svg>
    </div>
    <div *ngIf="showBody" class="w-6 h-6">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
            fill="#fff"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
            <path
                d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z" />
        </svg>
    </div>
</div>
<div [@smoothCollapse]="!showBody? 'initial': 'final'">
    <div [ngClass]="{ 'accordion-body': true, 'accordion-body-open': showBody }">
        <ng-content></ng-content>
    </div>
</div>