<!-- Parent -->
<div class="flex flex-col">
    <!-- Header -->
    <div class="mt-2 font-[Oswald] font-bold text-center max-2-5xl sm:mt-4 sm:p-4">
        <h1 class="text-4xl text-appBlue">Committed to Maintaining</h1>
        <h2 class="text-4xl text-appRed">Occupational Safety and Health</h2>
        <div class="p-2"></div>
        <h3 class="text-1xl text-black">We offer an array of Occupational Safety and Health services to provide any
            solution for your compliance needs.</h3>
    </div>
    <!-- Accordions -->
    <div class="bg-white m-2 flex flex-col justify-center md:flex-row md:space-x-4 md:m-5 z-10">
        <!-- Group 1 -->
        <div class="md:w-1/2 md:max-w-lg">
            <app-accordion>
                <!-- Asbestos -->
                <app-accordion-item title="Asbestos">
                    <ul class="space-y-0 w-full p-4 bg-slate-100 rounded">
                        <app-checkmark-item title="ACM removal documentation"></app-checkmark-item>
                        <app-checkmark-item title="Hazard Assessment"></app-checkmark-item>
                        <app-checkmark-item title="Industrial Hygiene Monitoring"></app-checkmark-item>
                        <app-checkmark-item title="Smoking Cessation"></app-checkmark-item>
                        <app-checkmark-item title="O & M Training"></app-checkmark-item>
                        <app-checkmark-item title="Written Program"></app-checkmark-item>
                    </ul>
                </app-accordion-item>
                <!-- Bloodborne Pathogens -->
                <app-accordion-item title="Bloodborne Pathogens">
                    <ul class="space-y-0 w-full p-4 bg-slate-100 rounded">
                        <app-checkmark-item title="Exposure Determination"></app-checkmark-item>
                        <app-checkmark-item title="Exposure Incident Report"></app-checkmark-item>
                        <app-checkmark-item title="HBV Declination"></app-checkmark-item>
                        <app-checkmark-item title="Training"></app-checkmark-item>
                        <app-checkmark-item title="Written Program"></app-checkmark-item>
                    </ul>
                </app-accordion-item>
                <!-- Confined Space -->
                <app-accordion-item title="Confined Spaces">
                    <ul class="space-y-0 w-full p-4 bg-slate-100 rounded">
                        <app-checkmark-item
                            title="Confined Space / Permit Required Confined Space Evaluation & Identification"></app-checkmark-item>
                        <app-checkmark-item title="Permit Development"></app-checkmark-item>
                        <app-checkmark-item title="Training"></app-checkmark-item>
                        <app-checkmark-item title="Entrant"></app-checkmark-item>
                        <app-checkmark-item title="Attendant"></app-checkmark-item>
                        <app-checkmark-item title="Entry Supervisor"></app-checkmark-item>
                        <app-checkmark-item title="Rescue"></app-checkmark-item>
                        <app-checkmark-item title="Air Monitoring Calibration"></app-checkmark-item>
                        <app-checkmark-item title="Written Program"></app-checkmark-item>
                    </ul>
                </app-accordion-item>
                <!-- Consulting Services -->
                <app-accordion-item title="Consulting Services">
                    <div class="flex flex-col items-center justify-center">
                        <p class="p-4">
                            ACTE2S is a team of dedicated consulting professionals whose practice is the structure of
                            implementation of workplace safety, corporate loss control, and regulatory
                            compliance.<br><br>
                            <strong>Let Us Assist You With</strong>
                        </p>
                        <ul class="space-y-0 w-full p-4 bg-slate-100 rounded">
                            <app-checkmark-item title="OSHA Citation Assistance"></app-checkmark-item>
                            <app-checkmark-item title="OSHA Abatement Requirements"></app-checkmark-item>
                            <app-checkmark-item
                                title="Accident Investigations and Accident Reporting"></app-checkmark-item>
                            <app-checkmark-item title="Emergency Action Plans"></app-checkmark-item>
                            <app-checkmark-item title="Program Development and Implementation"></app-checkmark-item>
                            <app-checkmark-item title="Facility Audit and Recommendations"></app-checkmark-item>
                        </ul>
                    </div>
                </app-accordion-item>
                <!-- Electrical Safety/Arc Flash -->
                <app-accordion-item title="Electrical Safety/Arc Flash">
                    <div class="flex flex-col items-center justify-center">
                        <ul class="space-y-0 w-full p-4 bg-slate-100 rounded">
                            <app-checkmark-item
                                title="Inspection and Identification: Electrical Systems, Flex Cords, GFCI Equipment, and Arc Flash"></app-checkmark-item>
                            <app-checkmark-item title="List of Qualified Electrical Personnel"></app-checkmark-item>
                            <app-checkmark-item title="Periodic Inspection Program"></app-checkmark-item>
                            <app-checkmark-item title="Training"></app-checkmark-item>
                            <app-checkmark-item title="Electrical Safety General"></app-checkmark-item>
                            <app-checkmark-item title="Arc Flash"></app-checkmark-item>
                            <app-checkmark-item title="Qualified Employees"></app-checkmark-item>
                            <app-checkmark-item title="Unqualified Employees"></app-checkmark-item>
                            <app-checkmark-item title="Written Program"></app-checkmark-item>
                        </ul>
                    </div>
                </app-accordion-item>
                <!-- Fall Protection -->
                <app-accordion-item title="Fall Protection">
                    <div class="flex flex-col items-center justify-center">
                        <ul class="space-y-0 w-full p-4 bg-slate-100 rounded">
                            <app-checkmark-item title="Elevated Work Program"></app-checkmark-item>
                            <app-checkmark-item
                                title="Semi-Annual Inspection for All Fall Protection Equipment"></app-checkmark-item>
                            <app-checkmark-item title="Training for Elevated Work"></app-checkmark-item>
                            <app-checkmark-item
                                title="General Fall Protection (Personal Fall Arrest System PFAS)"></app-checkmark-item>
                            <app-checkmark-item title="Competent Person Training (PFAS)"></app-checkmark-item>
                            <app-checkmark-item title="Scaffolding"></app-checkmark-item>
                            <app-checkmark-item title="Aerial Lift / Scissor Lift"></app-checkmark-item>
                            <app-checkmark-item title="Confined Space"></app-checkmark-item>
                            <app-checkmark-item title="Emergency Rescue"></app-checkmark-item>
                        </ul>
                    </div>
                </app-accordion-item>
                <!-- Hazard Communication -->
                <app-accordion-item title="Hazard Communication">
                    <ul class="space-y-0 w-full p-4 bg-slate-100 rounded">
                        <app-checkmark-item title="Chemical-Specific Training Programs"></app-checkmark-item>
                        <app-checkmark-item title="List of Hazardous Chemicals"></app-checkmark-item>
                        <app-checkmark-item title="SDSs"></app-checkmark-item>
                        <app-checkmark-item title="Develop To Meet All GHS Requirements"></app-checkmark-item>
                        <app-checkmark-item title="Interpretation"></app-checkmark-item>
                        <app-checkmark-item title="Organization"></app-checkmark-item>
                        <app-checkmark-item title="Written Program"></app-checkmark-item>
                    </ul>
                </app-accordion-item>
                <!-- Hearing Conservation -->
                <app-accordion-item title="Hearing Conservation">
                    <div class="flex flex-col items-center justify-center">
                        <ul class="space-y-0 w-full p-4 bg-slate-100 rounded">
                            <app-checkmark-item
                                title="Documentation of Trying Different Types of Hearing Protection"></app-checkmark-item>
                            <app-checkmark-item title="Industrial Hygiene Monitoring"></app-checkmark-item>
                            <app-checkmark-item title="Area"></app-checkmark-item>
                            <app-checkmark-item title="Personal"></app-checkmark-item>
                            <app-checkmark-item title="Employee Notifications"></app-checkmark-item>
                            <app-checkmark-item
                                title="Noise Survey Record Interpretation for OSHA 300 Log"></app-checkmark-item>
                            <app-checkmark-item title="Training"></app-checkmark-item>
                            <app-checkmark-item title="Written Program"></app-checkmark-item>
                        </ul>
                    </div>
                </app-accordion-item>
            </app-accordion>
        </div>
        <!-- Group 2 -->
        <div class="md:w-1/2 md:max-w-lg">
            <app-accordion>
                <!-- General Industry Safety Standards -->
                <app-accordion-item title="General Industry Safety Standards">
                    <div class="flex flex-col items-center justify-center">
                        <img class="" src="../../../assets/images/normal/20181022_130411.jpg" alt="">
                        <p class="p-4">
                            Complying with the ever-changing regulatory rules, standards, and laws and meeting
                            owner-specific safety requirements has become an overwhelming task that demands particular
                            expertise and understanding. If you are unsure of which specific laws to follow or how to
                            achieve compliance,
                            ACTE2S can help. Our staff of safety consulting professionals are educated, knowledgeable,
                            and have years of field experience to assist and support your business.
                        </p>
                    </div>
                </app-accordion-item>
                <!-- Industrial Hygiene -->
                <app-accordion-item title="Industrial Hygiene">
                    <div class="flex flex-col items-center justify-center">
                        <ul class="space-y-0 w-full p-4 bg-slate-100 rounded">
                            <app-checkmark-item
                                title="Identify health and safety problems in the workplace"></app-checkmark-item>
                            <app-checkmark-item title="Assess engineering controls/ventilation"></app-checkmark-item>
                            <app-checkmark-item
                                title="Conduct worksite and/or personal sampling and analysis for exposure levels for chemicals, lead, asbestos, silica, mold, and noise"></app-checkmark-item>
                            <app-checkmark-item
                                title="Assess all worksite environmental stressors"></app-checkmark-item>
                            <app-checkmark-item
                                title="Develop and /or recommend corrective measures to eliminate hazards and prevent future problems to include engineering and non-engineering controls (to include work practices)."></app-checkmark-item>
                            <app-checkmark-item
                                title="Assist in developing mandated health and safety programs"></app-checkmark-item>
                            <app-checkmark-item title="Interpret Safety Data Sheets (SDS)"></app-checkmark-item>
                            <app-checkmark-item title="Perform chemical speciation"></app-checkmark-item>
                            <app-checkmark-item title="Determine community exposures and risks"></app-checkmark-item>
                            <app-checkmark-item
                                title="Provide a full health risk analysis and hazard communication"></app-checkmark-item>
                        </ul>
                    </div>
                </app-accordion-item>
                <!-- Job Saftey (Hazard) Analysis -->
                <app-accordion-item title="Job Safety (Hazard) Analysis">
                    <ul class="space-y-0 w-full p-4 bg-slate-100 rounded">
                        <app-checkmark-item title="Evaluation of JSAs"></app-checkmark-item>
                        <app-checkmark-item title="JSA log"></app-checkmark-item>
                        <app-checkmark-item title="JSA Written Program"></app-checkmark-item>
                        <app-checkmark-item title="Training"></app-checkmark-item>
                    </ul>
                </app-accordion-item>
                <!-- Life Safety Code -->
                <app-accordion-item title="Life Safety Code">
                    <div class="flex flex-col items-center justify-center">
                        <p class="p-4">
                            ACTE2S performs inspections of buildings to identify life safety deficiencies and code
                            violations for all things related to the construction of stairways,
                            fire escapes, and other egress routes for fire drills in various occupancies, and for the
                            construction and arrangement of exit facilities for factories, schools,
                            and other occupancies. Written programs and procedures are evaluated for accuracy and
                            adequateness for exposures.<br><br>

                            ACTE2S areas of expertise: Assembly Occupancies, Board and Care Facilities, Building Service
                            and Fire Protection Equipment, Detention and Correctional Occupancies,
                            Educational and Day-Care Occupancies, Fire Protection Features, Fundamentals, Health Care
                            Occupancies, Industrial, Storage, and Miscellaneous Occupancies, Interior
                            Finish and Contents, Means of Egress, Mercantile and Business Occupancies, and Residential
                            Occupancies, released by the Correlating Committee on Safety to Life.
                        </p>
                    </div>
                </app-accordion-item>
                <!-- Lockout Tagout -->
                <app-accordion-item title="Lockout Tagout">
                    <div class="flex flex-col items-center justify-center">
                        <ul class="space-y-0 w-full p-4 bg-slate-100 rounded">
                            <app-checkmark-item title="LOTO Hazard Determination"></app-checkmark-item>
                            <app-checkmark-item
                                title="Develop/Implementation Written Control of Hazardous Energy Program."></app-checkmark-item>
                            <app-checkmark-item
                                title="Develop Audits/Inspections Reminder Systems"></app-checkmark-item>
                            <app-checkmark-item
                                title="Perform Hazard Determinations for Machine-Specific LOTO Procedures. (Electrical; Hydraulic; Pneumatic; Mechanical; Gravitational; Thermal; Chemical; Fluids and Gases; Water Under Pressure; and/or Steam)"></app-checkmark-item>
                            <app-checkmark-item
                                title="Develop/Implementation of Machine-Specific LOTO Procedures"></app-checkmark-item>
                            <app-checkmark-item
                                title="Develop/Implementation of File Plan System for Program Sustainability"></app-checkmark-item>
                            <app-checkmark-item title="Training – On-Site (Site/ Hazard Specific)"></app-checkmark-item>
                        </ul>
                    </div>
                </app-accordion-item>
                <!-- Personal Protective Equipment -->
                <app-accordion-item title="Personal Protective Equipment">
                    <ul class="space-y-0 w-full p-4 bg-slate-100 rounded">
                        <app-checkmark-item title="PPE Hazard Assessment"></app-checkmark-item>
                        <app-checkmark-item title="Prescription Safety Glasses Authorized Program"></app-checkmark-item>
                        <app-checkmark-item title="Safety shoe Authorized Program"></app-checkmark-item>
                        <app-checkmark-item title="Training"></app-checkmark-item>
                        <app-checkmark-item title="Written Program"></app-checkmark-item>
                    </ul>
                </app-accordion-item>
                <!-- Process Safety Management -->
                <app-accordion-item title="Process Safety Management">
                    <ul class="space-y-0 w-full p-4 bg-slate-100 rounded">
                        <app-checkmark-item title="Process Safety Information"></app-checkmark-item>
                        <app-checkmark-item title="Process Hazard Analysis"></app-checkmark-item>
                        <app-checkmark-item title="Operating Procedures"></app-checkmark-item>
                        <app-checkmark-item title="Employee Training"></app-checkmark-item>
                        <app-checkmark-item title="Pre-Startup Safety Review"></app-checkmark-item>
                        <app-checkmark-item title="Shutdown Procedures"></app-checkmark-item>
                        <app-checkmark-item title="Mechanical Integrity"></app-checkmark-item>
                        <app-checkmark-item title="Hot Work Permits (Line Break)"></app-checkmark-item>
                        <app-checkmark-item title="Emergency Planning and Response"></app-checkmark-item>
                        <app-checkmark-item title="Incident Investigations"></app-checkmark-item>
                        <app-checkmark-item title="Compliance Audits"></app-checkmark-item>
                    </ul>
                </app-accordion-item>
            </app-accordion>
        </div>
    </div>
    <!-- Header -->
    <div class="mt-2 font-[Oswald] font-bold text-center max-2-5xl sm:mt-4 sm:p-4">
        <h1 class="text-3xl text-appBlue">ACTE2S develops Site Specific Training</h1>
    </div>
    <!-- Video -->
    <div class="flex justify-center">
        <iframe title="vimeo-player" src="https://player.vimeo.com/video/754265250?h=ce53ebf609" width="640"
            height="360" frameborder="0" allowfullscreen></iframe>
    </div>

    <!-- Header -->
    <div class="mt-2 font-[Oswald] font-bold text-center max-2-5xl sm:mt-4 sm:p-4">
        <h1 class="text-3xl text-appBlue">Construction Site Safety</h1>
        <p class="p-4">
            ACTE2S can meet the many demands of large field projects by supplying the on-site project safety expertise
            your company needs.
            Safety Resources provides project safety professionals that implement and manage site-specific safety
            requirements by owners and construction contracts.
        </p>
    </div>
    <!-- Checklists -->
    <div class="max-w-5xl mx-auto columns-1 gap-4 md:columns-2 space-y-4 p-4">
        <!-- Group 1 -->
            <ul class="space-y-0 w-full p-4 bg-slate-300 rounded">
                <app-checkmark-item title="Safety Monitoring and Inspections"></app-checkmark-item>
                <app-checkmark-item title="Conduct Employee Safety Training And Orientation"></app-checkmark-item>
                <app-checkmark-item title="Attend Project Coordination Meetings"></app-checkmark-item>
                <app-checkmark-item title="On-Site Safety Specialists (Short and Long Term)"></app-checkmark-item>
                <app-checkmark-item title="Project Safety Management (Short and Long Term)"></app-checkmark-item>
                <app-checkmark-item title="Construction Site Safety Audits"></app-checkmark-item>
                <app-checkmark-item
                    title="OSHA Compliance - Expert Representative / Witness / Litigation Support"></app-checkmark-item>
                <app-checkmark-item
                    title="Staff Augmentation to Your Pre-Existing Safety Department"></app-checkmark-item>
                <app-checkmark-item title="Confined Space Entry Monitoring and Permitting"></app-checkmark-item>
                <app-checkmark-item
                    title="Fall Protection – Competent Person Training and Annual Inspections"></app-checkmark-item>
            </ul>
        <!-- Group 2 -->
            <ul class="space-y-0 w-full p-4 bg-slate-100 rounded">
                <app-checkmark-item
                    title="Develop Site-Specific Safety Plans and Task Hazard Assessments"></app-checkmark-item>
                <app-checkmark-item title="Complete Daily Safety Tasks and Responsibilities"></app-checkmark-item>
                <app-checkmark-item
                    title="Environmental Permitting and Programs (Storm Water Pollution Prevention Plan, Lead / Asbestos Mgmt. Plans, Etc.)"></app-checkmark-item>
                <app-checkmark-item title="Permitting"></app-checkmark-item>
                <app-checkmark-item title="Program Development"></app-checkmark-item>
                <app-checkmark-item title="Erosion Control – On-Site, Maintenance, and Closure"></app-checkmark-item>
                <app-checkmark-item
                    title="Industrial Hygiene Monitoring (Silica, Particulate Dust, Lead, Asbestos, Etc.)"></app-checkmark-item>
                <app-checkmark-item title="Implement Accident Management Systems"></app-checkmark-item>
                <app-checkmark-item title="Manage Regulatory Inspections"></app-checkmark-item>
                <p class="p-4">
                    ACTE2S works with every construction craft. We provide a variety of construction safety consulting
                    services.
                </p>
            </ul>
        <!-- Group 3 -->
            <ul class="space-y-0 w-full p-4 bg-slate-300 rounded">
                <app-checkmark-item title="General Contractors"></app-checkmark-item>
                <app-checkmark-item title="Trenching and Excavation Companies"></app-checkmark-item>
                <app-checkmark-item title="Tower Erectors"></app-checkmark-item>
                <app-checkmark-item title="Roofing Companies"></app-checkmark-item>
                <app-checkmark-item title="Sheet Metal and HVAC Companies"></app-checkmark-item>
            </ul>
        <!-- Group 4 -->
            <ul class="space-y-0 w-full p-4 bg-slate-100 rounded">
                <app-checkmark-item title="Electrical and Utility Companies"></app-checkmark-item>
                <app-checkmark-item title="Concrete and Masonry Companies"></app-checkmark-item>
                <app-checkmark-item title="Specialty Contractors"></app-checkmark-item>
                <app-checkmark-item title="Finishing Contractors"></app-checkmark-item>
            </ul>
    </div>
</div>