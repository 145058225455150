import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { careers } from './career-data';
import { CareerFormComponent } from './career-form/career-form.component';

@Component({
  selector: 'app-careers-page',
  standalone: true,
  imports: [CommonModule, CareerFormComponent],
  templateUrl: './careers-page.component.html',
  styleUrl: './careers-page.component.css',
})
export class CareersPageComponent {
  careers = careers;
}
