import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CareersServiceService } from '../careers-service.service';

@Component({
  selector: 'app-career-form',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './career-form.component.html',
  styleUrl: './career-form.component.css',
})
export class CareerFormComponent implements OnInit {
  fb = inject(FormBuilder);
  careerService = inject(CareersServiceService);

  careerForm!: FormGroup;
  sumbitMessage: string = '';
  submitting: boolean = false;

  ngOnInit(): void {
    this.careerForm = this.fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.pattern(/[A-Za-z\s'-]+/),
        ],
      ],
      email: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.maxLength(100),
          Validators.email,
        ],
      ],
      phone: [
        '',
        [
          Validators.minLength(10),
          Validators.maxLength(20),
          Validators.pattern(/^\+?[0-9()-\s]+$/),
        ],
      ],
      resume: [null, [Validators.required]],
    });
  }

  onFileSelected(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const fileList: FileList | null = inputElement.files;

    if (fileList && fileList.length > 0) {
      const file = fileList[0];
      if (file) {
        // Validate the file
        const validationError = this.validateFile(file);
        if (validationError) {
          this.careerForm.get('resume')?.reset();
          this.careerForm.get('resume')?.setErrors(validationError);
          console.log(this.careerForm);
          return;
        }
        // Read the file content and encode it as base64
        const reader = new FileReader();
        reader.onload = () => {
          const base64String = reader.result as string;

          // Remove data prefix from base64 string (data:application/pdf;base64,)
          const base64Data = base64String.split(',')[1];

          // Create an object with metadata and base64 data
          const fileData = {
            name: file.name,
            type: file.type,
            size: file.size,
            data: base64Data,
          };

          // Set the object as the value of the resume field
          this.careerForm.get('resume')?.setValue(fileData);
          this.careerForm.get('resume')?.setErrors(null);
        };
        reader.readAsDataURL(file);
        return;
      }
    }

    inputElement.value = '';
    this.careerForm.get('resume')?.reset();
  }

  validateFile(file: File): { [key: string]: any } | null {
    const allowedExtensions = ['pdf', 'doc', 'docx'];
    const allowedMimeTypes = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];
    const maxSize = 5 * 1024 * 1024; // 5MB
    const fileName = file.name;
    const fileSize = file.size;
    const fileExtension = fileName.split('.').pop()?.toLowerCase();
    const fileMimeType = file.type;

    // Check Allowed Extensions
    if (fileExtension && !allowedExtensions.includes(fileExtension)) {
      return { invalidAttachment: true };
    }

    // Check Allowed Mime Types
    if (!allowedMimeTypes.includes(fileMimeType)) {
      return { invalidAttachment: true };
    }

    // Check File Name Length
    if (fileName.length > 100) {
      return { invalidAttachment: true };
    }

    // Check File Size
    if (fileSize > maxSize) {
      return { invalidAttachment: true };
    }

    // Check Path Traversal
    if (
      fileName.includes('..') ||
      fileName.includes('../') ||
      fileName.includes('..\\')
    ) {
      return { invalidAttachment: true };
    }

    // Check Null Byte
    if (
      fileName.includes('\0') ||
      fileName.includes('%00') ||
      fileName.includes('\\0')
    ) {
      return { invalidAttachment: true };
    }

    // Check Executable Extensions
    const executableExtensions = ['exe', 'bat', 'sh', 'cmd'];
    if (fileExtension && executableExtensions.includes(fileExtension)) {
      return { invalidAttachment: true };
    }

    // Check Script Tags
    const scriptRegex = /<script.*?>|<\/script>/gi;
    if (scriptRegex.test(fileName)) {
      return { invalidAttachment: true };
    }

    // Check Unicode Characters
    const unicodeRegex = /[\u007F-\uFFFF]/;
    if (unicodeRegex.test(fileName)) {
      return { invalidAttachment: true };
    }

    return null; // No validation error
  }

  errorsFor(controlName: string): string {
    const control = this.careerForm.get(controlName);
    if (control && control.dirty && control.errors) {
      switch (controlName) {
        case 'name':
          if (control.errors['required']) {
            return `*Name is required`;
          } else if (control.errors['maxlength']) {
            return `Maximum length exceeded for ${controlName}`;
          } else if (control.errors['pattern']) {
            return `Invalid ${controlName}`;
          }
          break;
        case 'email':
          if (control.errors['required']) {
            return `*Email is required`;
          } else if (control.errors['maxlength']) {
            return `Maximum length exceeded for ${controlName}`;
          } else if (control.errors['email']) {
            return `Invalid email format`;
          }
          break;
        case 'phone':
          if (
            control.errors['minlength'] ||
            control.errors['maxlength'] ||
            control.errors['pattern']
          ) {
            return `*Invalid phone number`;
          }
          break;
        case 'resume':
          if (control.errors['required']) {
            return `*Resume attachment is required`;
          } else if (control.errors['invalidAttachment']) {
            return `*Invalid file attachment`;
          }
          break;
        default:
          break;
      }
    }
    return '';
  }

  onSubmit() {
    const name_field = this.careerForm.get('name')?.value;
    const email_field = this.careerForm.get('email')?.value;
    const phone_field = this.careerForm.get('phone')?.value;
    const resume_field = this.careerForm.get('resume')?.value;

    const formData = {
      name: name_field,
      email: email_field,
      phone: phone_field,
      resume: resume_field,
    };

    this.submitting = true;
    this.careerService.submitApplication(formData).subscribe(
      (response) => {
        console.log('Resume sumbitted successfully', response);
        this.sumbitMessage = 'Application submitted successfully';
        this.submitting = false;
      },
      (error) => {
        console.error('Error submitting resume', error);
        this.sumbitMessage = 'Error submitting resume. Please try again.';
        this.submitting = false;
      }
    );
  }
}
