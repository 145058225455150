import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-cta-button',
  standalone: true,
  imports: [],
  templateUrl: './cta-button.component.html',
  styleUrl: './cta-button.component.css',
})
export class CtaButtonComponent {
  @Input() title: string = '';
  @Output() buttonClick = new EventEmitter<Event>();

  onClick(event: Event): void {
    this.buttonClick.emit(event);
  }
}
