import { Routes } from '@angular/router';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { AccordionPageComponent } from './pages/accordion-page/accordion-page.component';
import { EnvironmentalPageComponent } from './pages/environmental-page/environmental-page.component';
import { EngineeringPageComponent } from './pages/engineering-page/engineering-page.component';
import { HealthSafetyPageComponent } from './pages/health-safety-page/health-safety-page.component';
import { CapabilitiesPageComponent } from './pages/capabilities-page/capabilities-page.component';
import { CareersPageComponent } from './pages/careers-page/careers-page.component';
import { PastPerformancePageComponent } from './pages/past-performance-page/past-performance-page.component';
import { ProjectMediaPageComponent } from './pages/project-media-page/project-media-page.component';
import { AboutUsPageComponent } from './pages/about-us-page/about-us-page.component';
import { ContactUsPageComponent } from './pages/contact-us-page/contact-us-page.component';

export const routes: Routes = [
  { path: 'accordion', component: AccordionPageComponent },
  { path: 'environmental', component: EnvironmentalPageComponent },
  { path: 'engineering', component: EngineeringPageComponent },
  { path: 'health-safety', component: HealthSafetyPageComponent },
  { path: 'capabilities', component: CapabilitiesPageComponent },
  { path: 'careers', component: CareersPageComponent },
  { path: 'past-performance', component: PastPerformancePageComponent },
  { path: 'project-media', component: ProjectMediaPageComponent },
  { path: 'about-us', component: AboutUsPageComponent },
  { path: 'contact-us', component: ContactUsPageComponent },
  { path: 'home', component: HomePageComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
];
