<!-- Parent -->
<div class="flex flex-col mb-10">
    <!-- Header -->
    <div class="mt-2 font-[Oswald] font-bold text-center max-2-5xl sm:mt-4 sm:p-4">
        <h1 class="text-4xl text-appBlue">Browse Through Our Previous </h1>
        <h2 class="text-4xl text-appRed">Compliance Projects</h2>
        <h3 class="text-2xl p-4">Experience Leads to Success</h3>
    </div>
    <!-- Pictures -->
    <div class="bg-white m-2 flex flex-col justify-center md:flex-row md:space-x-4 md:m-5 z-10">
        <!--Picture 1-->
        <div class="mb-4 md:w-1/2 md:max-w-lg">
            <img class="" src="../../../assets/images/normal/Veterans-Administration-Illinois-scaled-landscape.jpg"
                alt="">
        </div>
        <!--Picuture 2-->
        <div class="md:w-1/2 md:max-w-lg">
            <img class="" src="../../../assets/images/normal/manufacturing-scaled-landscape.jpg" alt="">
        </div>
    </div>

    <!-- Accordions -->
    <div class="bg-white m-2 flex flex-col justify-center md:flex-row md:space-x-4 md:m-5 z-10">
        <!-- Group 1 -->
        <div class="md:w-1/2 md:max-w-lg">
            <app-accordion>
                <!-- Bureau of Reclamation - Nebraska -->
                <app-accordion-item title="Bureau of Reclamation - Nebraska">
                    <div class="flex flex-col items-center justify-center">
                        <p class="p-4">
                            ACT Safe, LLC provided a comprehensive Health and Safety inspection for the Bureau of
                            Reclamation from September 2015 – May 2016. Location Medicine Creek Lodge Concession,
                            Cambridge, NE. The site host 63,000 visitors per year.
                        </p>
                    </div>
                </app-accordion-item>
                <!-- Food and Beverage -->
                <app-accordion-item title="Food and Beverage">
                    <div class="flex flex-col items-center justify-center">
                        <p class="p-4">
                            ACT Safe, LLC has worked with multiple clients in the Food and Beverage industry since 1999
                            to include Anheuser-Busch, Pepsi Bottling Group, ConAgra, Nestle Purina, and Smithfield.
                        </p>
                    </div>
                </app-accordion-item>
                <!-- Manufacturing -->
                <app-accordion-item title="Manufacturing">
                    <div class="flex flex-col items-center justify-center">
                        <p class="p-4">
                            ACT Safe, LLC has worked with multiple clients in Manufacturing since 1993 to include Oil
                            and Gas (Sunoco), Agribusiness (animal feed, fertilizer, grain, and farm equipment
                            manufacturers), Steel, Chemical, Pharmaceuticals, Construction & Services, and
                            Distribution/Transportation.
                        </p>
                    </div>
                </app-accordion-item>
                <!-- NAVFAC – Texas (IDIQ) -->
                <app-accordion-item title="NAVFAC – Texas (IDIQ)">
                    <div class="flex flex-col items-center justify-center">
                        <p class="p-4">
                            ACT Safe, LLC is providing services to the Department of the Navy to perform Remediation
                            Construction / Repair Services 2022 - 2027.
                        </p>
                    </div>
                </app-accordion-item>
                <!-- Veterans Administration - Alabama -->
                <app-accordion-item title="Veterans Administration - Alabama">
                    <div class="flex flex-col items-center justify-center">
                        <p class="p-4">
                            ACT Safe, LLC is providing services to the Department of Veteran Affairs Tuscaloosa VA
                            Healthcare System for Respiratory Protection refresher training and Fit Testing for 800
                            employees. 2022
                        </p>
                    </div>
                </app-accordion-item>
                <!-- Veterans Administration – Florida (IDIQ) -->
                <app-accordion-item title="Veterans Administration – Florida (IDIQ)">
                    <div class="flex flex-col items-center justify-center">
                        <p class="p-4">
                            ACT Safe, LLC is providing services to the Department of Veteran Affairs Bay Pines (Florida)
                            VA Healthcare System (BPVAHCS) for emergency response, excavation, remediation (including
                            potential storage tank removal from site), groundwater well installation, groundwater
                            monitoring, water sampling, and reporting services for contaminated or impacted
                            soil/groundwater services. 2018 - 2027 (Issued 2nd IDIQ after receiving "Exceptional"
                            CPARS.)
                        </p>
                    </div>
                </app-accordion-item>
                <!-- Veterans Administration - Illinois -->
                <app-accordion-item title="Veterans Administration - Illinois">
                    <div class="flex flex-col items-center justify-center">
                        <p class="p-4">
                            ACT Safe, LLC provided Industrial Hygiene limited air monitoring for the Veterans
                            Administration from January 2017 – March 2017 at its Danville, Illinois location.
                        </p>
                    </div>
                </app-accordion-item>
            </app-accordion>
        </div>
        <!-- Group 2 -->
        <div class="md:w-1/2 md:max-w-lg">
            <app-accordion>
                <!-- Veterans Administration - Illinois -->
                <app-accordion-item title="Veterans Administration - Illinois">
                    <div class="flex flex-col items-center justify-center">
                        <p class="p-4">
                            ACT Safe, LLC provided Underground Storage Tank (UST) assessment and investigation for the
                            Veterans Administration from January – March 2016 at its Danville, Illinois location.
                        </p>
                    </div>
                </app-accordion-item>
                <!-- Veterans Administration - Illinois -->
                <app-accordion-item title="Veterans Administration - Illinois">
                    <div class="flex flex-col items-center justify-center">
                        <p class="p-4">
                            ACT Safe, LLC provided Underground Storage Tank (UST) assessment/investigation and
                            Abandonment in Place services for the Veterans Administration from November 2016 – March
                            2017 at its Hines, Illinois location.
                        </p>
                    </div>
                </app-accordion-item>
                <!-- Veterans Administration - Kansas -->
                <app-accordion-item title="Veterans Administration - Kansas">
                    <div class="flex flex-col items-center justify-center">
                        <p class="p-4">
                            ACT Safe, LLC provided a Construction Safety survey and training for the Veterans
                            Administration in August 2017 – ongoing at its Leavenworth and Topeka, Kansas locations.
                        </p>
                    </div>
                </app-accordion-item>
                <!-- Veterans Administration - North Carolina (IDIQ) -->
                <app-accordion-item title="Veterans Administration - North Carolina (IDIQ)">
                    <div class="flex flex-col items-center justify-center">
                        <p class="p-4">
                            ACT Safe, LLC is providing services to the Department of Veteran Affairs Durham VA
                            Healthcare System for NESHAP Semi-Annual Asbestos Operations and Maintenance (O&M) Program
                            Periodic Surveillance (aka Semi-Annual Asbestos Inspection of known friable asbestos). 2018
                            - 2022
                        </p>
                    </div>
                </app-accordion-item>
                <!-- Veterans Administration - South Dakota -->
                <app-accordion-item title="Veterans Administration - South Dakota">
                    <div class="flex flex-col items-center justify-center">
                        <p class="p-4">
                            ACT Safe, LLC provided Spill Prevention, Control & Countermeasure Plan and Hazardous Waste
                            Contingency Plan for the Veterans Administration from September 2016 – April 2017 at its
                            Sioux Falls, South Dakota location.
                        </p>
                    </div>
                </app-accordion-item>
                <!-- Veterans Administration - Tennessee -->
                <app-accordion-item title="Veterans Administration - Tennessee">
                    <div class="flex flex-col items-center justify-center">
                        <p class="p-4">
                            ACT Safe, LLC provided Industrial Hygiene services for the Veterans Administration from
                            August – December 2017 at its Memphis, Tennessee location.
                        </p>
                    </div>
                </app-accordion-item>
                <!-- Veterans Administration - Wyoming -->
                <app-accordion-item title="Veterans Administration - Wyoming">
                    <div class="flex flex-col items-center justify-center">
                        <p class="p-4">
                            ACT Safe, LLC provided Asbestos Sixteen-Hour Operations and Maintenance (O & M) Worker
                            Training for the Veterans Administration from June 2017 – July 2017 at its Sheridan, Wyoming
                            location.
                        </p>
                    </div>
                </app-accordion-item>
            </app-accordion>
        </div>
    </div>
</div>