import { Component } from '@angular/core';
import { CtaButtonComponent } from '../../components/cta-button/cta-button.component';
import { AccordionComponent } from '../../components/accordion/accordion.component';
import { AccordionItemComponent } from '../../components/accordion/accordion-item.component';
import { CheckmarkItemComponent } from '../../components/checkmark-item/checkmark-item.component';
import { ThemeService } from '../../theme/theme-service';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-page',
  standalone: true,
  imports: [
    CommonModule,
    CtaButtonComponent,
    AccordionComponent,
    AccordionItemComponent,
    CheckmarkItemComponent,
  ],
  templateUrl: './home-page.component.html',
  styleUrl: './home-page.component.css',
})
export class HomePageComponent {
  router: Router;
  private themeService: ThemeService;

  designations: string[] = [
    '8(a) EXP 12-23-2029',
    'DBE',
    'EBE',
    'EDWOSB',
    'SBE',
    'SDB',
    'SDVOSB',
    'VOSB',
    'WVOSB',
    'WOSB',
  ];

  registrations: string[] = [
    'CAGE - 7CVG3',
    'DUNS - 079689293',
    'UEI - SMZHZ31DN6T3',
  ];

  naicsCodes: string[] = [
    'Primary 562910',
    '541614',
    '541620 and 541690',
    '541711',
    '518210',
    '541712',
    '524291',
    '541820',
    '541310',
    '561320',
    '541330',
    '561612',
    '541350',
    '611430',
    '541380',
    '611699',
    '541420',
    '611710',
    '541511',
    '621210',
    '541512',
    '621399',
    '541611',
  ];

  constructor(router: Router, themeService: ThemeService) {
    this.router = router;
    this.themeService = themeService;
  }

  getColor(colorName: string, defaultColor: string = '#113C5C'): string {
    return this.themeService.getColor(colorName, defaultColor);
  }

  handleContactUsButtonClick(event: Event): void {
    this.router.navigate(['/contact-us']);
  }
}
