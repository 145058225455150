import { Component } from '@angular/core';
import { AccordionComponent } from '../../components/accordion/accordion.component';
import { AccordionItemComponent } from '../../components/accordion/accordion-item.component';

@Component({
  selector: 'app-accordion-page',
  standalone: true,
  imports: [AccordionComponent, AccordionItemComponent],
  templateUrl: './accordion-page.component.html',
  styleUrl: './accordion-page.component.css',
})
export class AccordionPageComponent {}
