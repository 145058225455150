import { Component } from '@angular/core';
import { AccordionComponent } from '../../components/accordion/accordion.component';
import { AccordionItemComponent } from '../../components/accordion/accordion-item.component';
import { CheckmarkItemComponent } from '../../components/checkmark-item/checkmark-item.component';

@Component({
  selector: 'app-health-safety-page',
  standalone: true,
  imports: [
    AccordionComponent,
    AccordionItemComponent,
    CheckmarkItemComponent,
  ],
  templateUrl: './health-safety-page.component.html',
  styleUrl: './health-safety-page.component.css'
})
export class HealthSafetyPageComponent {

}
