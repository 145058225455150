<!-- Parent -->
<div class="flex flex-col">
    <!-- Header -->
    <div class="mt-2 mb-4 font-[Oswald] font-bold text-center max-2-5xl sm:mt-4 sm:p-4">
        <h1 class="text-4xl text-appBlue">Client Focus Driven</h1>
        <h2 class="text-4xl text-appRed">We will serve you with the same loyalty, honor, integrity, respect, and
            selflessness as we served our country.</h2>
    </div>
    <!-- Picture inline with summary of Lorna -->
    <div class="bg-white m-2 flex items-center flex-col justify-center md:flex-row md:space-x-4 z-10">
        <!-- Picture -->
        <div class="p-6 md:w-1/5 mb-4 text-center">
            <img class="rounded-full" src="../../../assets/images/normal/2022-10-13-06-39-11-660-263x300-circle.jpg"
                alt="">
            <p><strong>Lorna G. Puntillo</strong></p>
            <p>CEO</p>
        </div>
        <!-- Summary -->
        <div class="md:w-4/5 text-left">
            <p>Lorna G. Puntillo, CEO, has worked over 30 years in the fields of Environmental, Health and Safety in the
                private and private sector. Working with hundreds of businesses, industries, and local, state and
                federal agencies. A retired veteran of the United States Air Force, Ms. Puntillo started her career as a
                Bioenvironmental Engineering Specialist (1989) and still holds a deep commitment to serving our nation’s
                current military force and supporting her fellow veterans and their families with employment
                opportunities. Ms. Puntillo has held many prestigious positions to include her appointment by the
                Governor of Iowa as an Environmental Protection Commissioner. Ms. Puntillo is passionate about the
                protection of the workforce as well as preservation of our vital resources. She believes that through
                understanding regulations and obstacles we are faced with, we can find a meaningful and cost-effective
                solutions.</p>
        </div>
    </div>
    <!-- Spacer -->
    <div class="p-4"></div>
    <!-- Divider Line -->
    <div class="bg-slate-500 h-[1px] mr-2 ml-2 mb-6"></div>
    <!-- 2 column after divider -->
    <div class="bg-white m-2 flex items-center flex-col justify-center md:flex-row md:space-x-4 z-10">
        <!-- Column 1 -->
        <div class="md:w-1/2 md:max-w-lg mb-4">
            <p class="mb-8 sm:mb-20">
                ACTE2S is a SBA 8(a), Service Disabled Veteran / Woman Owned Small Business. ACTE2S is a cooperative of
                former compliance officers, compliance managers, and occupational specialist. Our company offers
                temporary, part-time, and full-time Safety & Health (OSHA / CCOHS), Transportation (DOT) and
                Environmental (EPA), Engineering, Security (SWAT & Homeland Security – Foreign and Domestic) services to
                support companies in developing sustainable compliance of Federal, State, and Local authorities.
                <br><br>
                ACTE2S works with organizations seeking to identify a comprehensive consulting resource to address some
                or all of their compliance issues, as well as firms wishing to develop working partnerships and alliance
                opportunities. As a compliance leader, ACTE2S is committed to developing long-term, mutually beneficial
                professional relationships with our clients and business partners while creating a challenging and
                rewarding workplace for our professional staff.
            </p>
            <img src="../../../assets/images/normal/20171115_085219-scaled.jpg" alt="">
        </div>
        <!-- Column 2 -->
        <div class="md:w-1/2 md:max-w-lg">
            <ul class="pace-y-0 w-full p-4 bg-appBlue rounded text-white">
                <img src="../../../assets/images/normal/3972165214_ea866d7e76_o-a220eaa-scaled.jpg" alt="">
                <h1 class="mt-10 text-4xl"><strong>Our Commitment to You</strong></h1>
                <p><br>ACTE2S provides a full range of compliance services, addressing the needs of the private and
                    public sectors. Whether you require hazard audits, policy and procedures development, safety
                    training or compliance assistance, your organization’s attention to compliance is our commitment to
                    you.
                    <br><br>
                    ACTE2S’s team has experience in various businesses, industries, and municipalities allowing us the
                    ability to afford you – real answers and solutions – in real time. Our goal is to minimize or
                    eliminate compliance issues including exposure. The dynamics of our team members allow us the
                    opportunity to bring multi-disciplinary functionality to our clients in a way never seen before by
                    our competitors. We focus on making compliance easy so our clients can focus on doing what they do
                    best – “building their companies”.
                </p>

            </ul>
        </div>
    </div>
    <!-- Accordions -->
    <div class="bg-white m-2 flex flex-col justify-center md:flex-row md:space-x-4 z-10 mb-10">
        <!-- Group 1 -->
        <div class="md:w-1/2 md:max-w-lg">
            <app-accordion>
                <!-- Certifications -->
                <app-accordion-item title="Certifications">
                    <ul class="space-y-0 w-full p-4 bg-slate-100 rounded">
                        <img src="../../../assets/images/normal/1.jpg" alt="" class="pb-4 pt-4">
                        <li><strong>SBA - 8(a) Expiration 12-2029</strong></li>
                        <li>SDB – Small Disadvantaged Business (sba.gov)</li>
                        <li><strong>SDVOSB – Service Disable Veteran Owned Small Business (va.gov)</strong></li>
                        <li>VOSB – Veteran Owned Small Business (va.gov)</li>
                        <li>WOB – Woman Owned Business (Sam.gov)</li>
                        <li><strong>WOSB – Woman Owned Small Business (sam.gov)</strong></li>
                        <li><strong>GSA Schedule - Contract Number</strong></li>
                        <li><strong>47QRAA22D000Y</strong></li>
                        <li><strong>Exp. 10-18-2026</strong></li>
                    </ul>
                </app-accordion-item>
                <!-- Federal Clients -->
                <app-accordion-item title="Federal Clients">
                    <ul class="space-y-0 w-full p-4 bg-slate-100 rounded">
                        <app-checkmark-item title="Bureau of Reclamation"></app-checkmark-item>
                        <app-checkmark-item
                            title="Veteran Administration VISN 6, 7, 8, 9, 12, 13, 15 & 23"></app-checkmark-item>
                        <app-checkmark-item title="USAF"></app-checkmark-item>
                        <app-checkmark-item title="USACE"></app-checkmark-item>
                        <app-checkmark-item title="USCG"></app-checkmark-item>
                        <app-checkmark-item title="USN"></app-checkmark-item>
                        <app-checkmark-item title="USDA"></app-checkmark-item>
                    </ul>
                </app-accordion-item>
            </app-accordion>
        </div>
        <!-- Group 2 -->
        <div class="md:w-1/2 md:max-w-lg">
            <app-accordion>
                <!-- NAICS -->
                <app-accordion-item title="NAICS">
                    <ul class="space-y-0 w-full p-4 bg-slate-100 rounded">
                        <h1 class="text-4xl text-appBlue"><strong>NAICS Codes</strong></h1>
                        <li><strong>(Primary) 541620 – Environmental Consulting Services</strong></li>
                        <li><strong>(Secondary) 562910 - Environmental Remediation</strong></li>
                        <li>541310 – Architectural Services</li>
                        <li>541330 – Engineering Services</li>
                        <li>541611 – Administrative Management and General Management Consulting Services</li>
                        <li><strong>541690 - All Other Professional, Scientific, and Technical Services</strong></li>
                        <li>541820 – Public Relations Agencies</li>
                        <li>541990 – All Other Professional, Scientific, and Technical Services</li>
                        <li>611430 – Professional And Management Development Training</li>
                        <li>611699 – All Other Miscellaneous Schools and Instruction</li>
                        <li>611710 – Educational Support Services</li>
                        <li>621399 – Offices of All Other Miscellaneous Health Practitioners</li>
                        <li>922160 – Fire Protection</li>
                        <li>922190 – Other Justice, Public Order, and Safety Activities</li>
                        <li>926120 – Regulation and Administration of Transportation Programs</li>
                        <li>926150 – Regulation, Licensing, and Inspection of Miscellaneous Commercial Sectors</li>
                    </ul>
                </app-accordion-item>
                <!-- Private Clients -->
                <app-accordion-item title="Private Clients">
                    <ul class="space-y-0 w-full p-4 bg-slate-100 rounded">
                        <app-checkmark-item
                            title="Food: Anheuser-Busch, ConAgra, Interbake Foods (Girl Scout Cookies), Nestle Purina, Pepsi & Smithfield"></app-checkmark-item>
                        <app-checkmark-item title="Manufacturing: Coleman Company, Lennox Mfg."></app-checkmark-item>
                        <app-checkmark-item title="Oil & Gas: Plains Marketing L.P. & Sunoco"></app-checkmark-item>
                        <app-checkmark-item title="Pharma: Aspen API, Bioiberica, Merck & Pfizer"></app-checkmark-item>
                    </ul>
                </app-accordion-item>
            </app-accordion>
        </div>
    </div>
</div>