import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Router } from '@angular/router';
import { CtaButtonComponent } from '../../components/cta-button/cta-button.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-capabilities-page',
  standalone: true,
  imports: [
    CommonModule, RouterModule, CtaButtonComponent
  ],
  templateUrl: './capabilities-page.component.html',
  styleUrl: './capabilities-page.component.css'
})
export class CapabilitiesPageComponent {
  handleCapabilitiesButtonClick(event: Event){
    console.log(event)
  }
}
